const cs = {
	"Account": {
		"Name": "Jméno",
		"Email": "E-mail",
		"Address": "Adresa",
		"AccNum": "Číslo účtu",
		"Diners": "Strávníci",
		"Roles": "Oprávnění",
		"SetRole": "Nastavit oprávnění",
		"IsDiners": "Je strávník",
		"SetAsDiner": "Nastavit jako strávníka",
		"WithoutDiner": "Bez strávníka",
		"Delete": "Smazat",
		"DeletedSuccessfuly": "Účet byl úspěšně smazán.",
		"ConfirmDelete": "Opravdu chcete smazat vybraný účet?",
		"ConfirmForceDelete": "Pro vybraný účet již existují data. Opravdu jej chcete smazat?"
	},
	"Alergen": {
		"Id": "ID",
		"Cs": "Česky",
		"En": "Anglicky",
		"ModalTitle": "Alergen",
		"SaveSuccess": "Alergen byl úspěšně uložen.",
		"DeleteSuccess": "Alergen byl úspěšně smazán."
	},
	"Billing": {
		"User": "Uživatel",
		"Diner": "Strávník",
		"CreditBefore": "Počáteční stav kreditu",
		"Menu": "Hlavní jídla",
		"Snack": "Svačiny",
		"Total": "Celkem",
		"Credit": "Konečný stav kreditu",
		"Recalculate": "Přepočítat",
		"ConfirmRecalculate": "Opravdu chcete spustit přepočet vyúčtování za {period} a všech následujících vyúčtování?",
		"RecalculateSent": "Požadavek na přepočet byl úspěšně odeslán. Na minutku si odpočiňte a pak obnovte stránku.",
		"Suma": "Celkem",
		"Balance": "Bilance",
		"Deposit": "Vklady"
	},
	"Btn": {
		"Add": "Přidat",
		"Update": "Upravit",
		"Delete": "Smazat",
		"Cancel": "Zrušit",
		"Save": "Uložit",
		"Export": "Export",
		"Import": "Import",
		"Close": "Zavřít",
		"Select": "Vybrat",
		"Send": "Odeslat",
		"Print": "Tisk"
	},
	"Codelist": {
		"All": "< vše >",
		"ChoseDiner": "< vyber strávníka >",
		"Yes": "Ano",
		"No": "Ne"
	},
	"Daily": {
		"Diner": "Strávník",
		"Group": "Skupina"	
	},
	"Days": {
		"Basic": {
			"0": "Neděle",
			"1": "Pondělí",
			"2": "Úterý",
			"3": "Středa",
			"4": "Čtvrtek",
			"5": "Pátek",
			"6": "Sobota"
		},
		"Short": {
			"0": "Ne",
			"1": "Po",
			"2": "Út",
			"3": "St",
			"4": "Čt",
			"5": "Pá",
			"6": "So"
		}
	},
	"Diner": {
		"Name": "Jméno",
		"Group": "Skupina",
		"Account": "Účet",
		"UserDiner": "Je uživatel",
		"NotPay": "All inclusive",
		"GlutenFree": "Bezlepková a bezvaječná dieta",
		"Move": "Přesunout",
		"ModalTitle": "Strávník",
		"MoveModalTitle": "Přesunout strávníky",
		"MoveSubmit": "Přesunout",
		"MoveSuccess": "Strávníci byli úspěšně přesunuti.",
		"PaymentId": "Variabilní symbol",
		"Balance": "Stav konta",
		"Unposted": "Nezúčtované objednávky",
		"ConfirmDelete": "Opravdu chcete smazat vybraného strávníka?",
		"DeleteSuccess": "Strávník byl úspěšně smazán.",
		"DeleteDependencies": "Strávník, které ho se chystáte smazat, již obsahuje data (objednávky, platby, vyúčtování). Opravdu jej chcete smazat i se všemi daty?",
		"Hidden": "Skrýt v objednávkách",
		"AssignStudent": "Spárovat se studentem",
		"AssignModalTitle": "Spárování strávníka se studentem",
		"Student": "Student",
		"Assign": "Spárovat",
		"AssignSuccess": "Spárování bylo úspěšně provedeno.",
		"AssignConfirm": "Spárováním strávníka dojde ke spojení a sjednocení parametrů jako název, skupina, účet a all-inclusive. Opravdu chcete spárovat vybraného strávníka se záznamem studenta?",
		"From": "Školní rok od",
		"To": "Školní rok do"
	},
	"Emails": {
		"To": "Komu",
		"Subject": "Předmět",
		"Message": "Zpráva",
		"SelectTo": "Vyber příjemce",
		"All": "Všichni",
		"SendSuccess": "E-mail byl úspěšně odeslán."
	},
	"Filter": {
		"Period": "Období",
		"Diner": "Strávník",
		"Group": "Skupina",
		"Date": "Datum",
		"Title": "Filtr",
		"AllInclusive": "All inclusive",
		"IsUser": "Je uživatel",
		"Name": "Jméno",
		"Type": "Typ",
		"From": "Od",
		"To": "Do",
		"NoGroup": "Bez skupiny"
	},
	"Group": {
		"Title": "Skupina",
		"Name": "Název",
		"Order": "Pořadí v Měsíčním přehledu",
		"AddSuccess": "Skupina byla úspěšně vytvořena.",
		"EditSuccess": "Skupina byla úspěšně upravena.",
		"DeleteConfirm": "Opravdu chcete smazat vybranou skupinu?",
		"DeleteConfirmDependent": "Vybraná skupina je svázána s dalšími daty (strávníci, studenti, kroužky). V případě smazání skupiny by byli strávníci a studenti vyjmuti ze skupiny, a u kroužků by byla z filtru skupina odstraněna. Opravdu chcete smazat vybranou skupinu?"
	},
	"Header": {
		"Login": "Přihlásit",
		"Register": "Registrovat",
		"Menu": "Jídelní lístek",
		"Payments": "Platby",
		"Logout": "Odhlásit",
		"Administration": "Správa aplikace",
		"Accounts": "Uživatelské účty",
		"Diners": "Strávníci",
		"Groups": "Skupiny",
		"Lunch": "Jídelní lístek",
		"Profile": "Profil",
		"AdminPayments": "Platby",
		"Billing": "Vyúčtování",
		"Alergens": "Alergeny",
		"Settings": "Nastavení systému",
		"Reports": "Přehledy",
		"OrderSummary": "Počty objednávek",
		"OrderDay": "Objednávky strávníků",
		"OrderMonth": "Měsíční objednávky",
		"Emails": "Zasílání e-mailů",
		"About": {
			"Title": "O aplikaci",
			"Name": "Název",
			"App": "IMSP Obědový systém",
			"Version": "Verze",
			"Copyright": "© 2019 Copyright by IMSP. All right reserved.",
			"By": "Vytvořil"
		},
		"Notice": "Zaslané upozornění",
		"OrderAdmin": "Objednávky"
	},
	"ImportResult": {
		"Title": "Výsledek importu"
	},
	"Lunch": {
		"SaveSuccess": "Menu bylo úspěšně nastaveno.",
		"ImportSuccess": "Bylo úspěšně importováno {{count}} záznamů.",
		"ImportError": "Bylo importováno {{count}} záznamů, {{errCount}} skončilo chybou:</br>{{errors}}",
		"Period": "Období",
		"Lang": "Jazyk",
		"DownloadImport": "Stáhnout vzorový soubor",
		"ColDate": "Datum",
		"ColLang": "Jazyk",
		"ColSoup": "Polévka",
		"RemoveSuccess": "Menu bylo úspěšně smazáno.",
		"ConfirmRemove": "Opravdu chcete smazat vybraný záznam?"
	},
	"LunchMenu": {
		"NoDiner": "Vítejte v IMSP Obědovém systému. Zatím nemáte ke svému účtu nastaveného žádného strávníka, takže nemůžete objednávat.",
		"Balance": "Stav konta",
		"UnpostedOrders": "Nezaúčtované objednávky",
		"PaymentInfo": "Zobrazit údaje pro platbu",
		"Alergens": "Alergeny",
		"NoAlergens": "Alergeny nejsou zadány",
		"AlergensNotParsed": "Alergeny nebyly rozpoznány",
		"Modal": {
			"Title": "Platební údaje",
			"Number": "Číslo účtu",
			"Symbol": "Variabilní symbol",
			"Amount": "Částka",
			"Note": "Částka platby je pouze orientační údaj, vypočítaný na základě stavu konta a objednávek v daném měsíci.",
			"Close": "Zavřít",
			"Ss": "Specifický symbol",
			"NewNumber": "Pozor, změna bankovního účtu. Upravte si, prosím, trvalý příkaz."
		},
		"ExportMenu": "Export jídelníčku",
		"MissingBillingInfo": {
			"Part1": "Vážený uživateli, nemáte vyplněny fakturační údaje. Nastavte si je prosím v sekci ",
			"Part2": ". Pokud nebudete mít vypněny fakturační údaje, nebude vám dovoleno objednávat obědy."
		},
		"LimitExceeded": "Byla překročena maximální hodnota dluhu.",
		"HidePast": "Skrýt staré"
	},
	"MealType": {
		"0": "Polévka",
		"1": "Masové menu",
		"2": "Vegetariánské menu",
		"3": "Bezlepková a bezvaječná dieta",
		"4": "Svačina"
	},
	"Monthly": {
		"Diner": "Strávník",
		"Group": "Skupina",
		"Print": {
			"Title": "Měsíční přehled objednávek",
			"All": "všechny"
		}
	},
	"Months": {
		"Basic": {
			"1": "Leden",
			"2": "Únor",
			"3": "Březen",
			"4": "Duben",
			"5": "Květen",
			"6": "Červen",
			"7": "Červenec",
			"8": "Srpen",
			"9": "Září",
			"10": "Říjen",
			"11": "Listopad",
			"12": "Prosinec"
		},
		"OfDate": {
			"1": "Ledna",
			"2": "Února",
			"3": "Března",
			"4": "Dubna",
			"5": "Května",
			"6": "Června",
			"7": "Července",
			"8": "Srpna",
			"9": "Září",
			"10": "Října",
			"11": "Listopadu",
			"12": "Prosince"
		},
		"Short": {
			"1": "Led",
			"2": "Úno",
			"3": "Bře",
			"4": "Dub",
			"5": "Kvě",
			"6": "Čvn",
			"7": "Čvc",
			"8": "Srp",
			"9": "Zář",
			"10": "Říj",
			"11": "Lis",
			"12": "Pro"
		}
	},
	"Notice": {
		"Time": "Čas odeslání",
		"Period": "Období",
		"Result": "Výsledek",
		"CountText": "Počet dlužníků",
		"Ok": "Vše OK",
		"Errors": "Chyby",
		"Diner": "Strávník",
		"Balance": "Stav konta",
		"Error": "Chyba"
	},
	"OrderView": {
		"Date": "Datum",
		"Count": "Počet"
	},
	"Payment": {
		"TypePayment": "Platba",
		"TypeDeposite": "Vklad",
		"Time": "Čas",
		"Diner": "Strávník",
		"Type": "Typ",
		"Description": "Popis",
		"Amount": "Částka",
		"TotalPayment": "Celkem platby",
		"AddTitle": "Nový záznam",
		"SaveSuccess": "Záznam byl úspěšně uložen.",
		"ImportSuccess": "Bylo úspěšně importováno {{count}} záznamů.",
		"ImportError": "Bylo importováno {{count}} záznamů, {{errCount}} skončilo chybou:</br>{{errors}}",
		"Balance": "Stav konta",
		"TotalDeposite": "Celkem vklady",
		"ImportExample": "Ukázový importní soubor",
		"Import": "Import vkladů",
		"ConfirmDelete": "Opravdu chcete smazat vybraný záznam?",
		"MealType": "Jídlo",
		"EditTitle": "Úprava záznamu",
		"SumaPayment": "Celkem platby",
		"SumaDeposit": "Celkem vklady",
		"Bal": "Bilance"
	},
	"Profile": {
		"Profile": "Profil",
		"Name": "Jméno",
		"Email": "E-mail",
		"Invoice": "Fakturační údaje",
		"Address": "Adresa",
		"Account": "Číslo účtu",
		"Ico": "IČO",
		"ProfileSuccess": "Profil byl uložen.",
		"BillingSuccess": "Fakturační údaje uloženy."
	},
	"Settings": {
		"MealPrice": "Ceny jídel",
		"Other": "Ostatní parametry",
		"AccountNumber": "Číslo účtu",
		"DebitLimit": "Limit nedoplatku",
		"MealPriceSuccess": "Ceny jídel byly úspěšně uloženy.",
		"OtherSuccess": "Ostatní parametry byly úspěšně uloženy.",
		"SpecificSymbol": "Specifický symbol",
		"SendingOrders": "Zasílání objednávek",
		"SoEnabled": "Aktivní",
		"SoTo": "Komu",
		"SoCc": "Kopie",
		"SendOrdersSuccess": "Zasílání objednávek bylo úspěšně uloženo.",
		"SendBalanceWarning": "Zasílání upozornění na záporný stav konta",
		"SendNoticeToOrder": "Zasílání připomínky na objednání obědů",
		"SbwEnabled": "Aktivní",
		"BalanceWarningDay": "Den odeslání v měsíci",
		"SendNoticeToOrderDayBefore": "Počet dnů před termínem",
		"SendBalanceWarningSuccess": "Nastavení zasílání upozornění na záporný stav konta bylo úspěšně uloženo.",
		"SendNoticeToOrderSuccess": "Nastavení zasílání připomínky na obědnání obědů bylo úspěšně uloženo."
	},
	"Units": {
		"Money": "Kč"
	},
	"Order": {
		"Date": "Datum",
		"Diner": "Strávník",
		"Lunch": "Oběd",
		"Snack": "Svačina",
		"ConfirmDelete": "Opravdu chcete smazat vybrané záznamy? Zaúčtované objednávky budou smazány včetně plateb. Pokud jsou mazány objednávky z minulého období, je potřeba spustit přepočet vyúčtování.",
		"DeletedSuccessfuly": "Objednávky byly úspěšně smazány."
	},
	"General": {
		"SchoolYear": "Školní rok"
	}
};

export default cs;
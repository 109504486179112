import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';

import './emails.scss';


// receiver types
const RT_ALL = 0;
const RT_GROUP = 1;
const RT_USER = 2;

class Emails extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            modal: {
                open: false,
                to: {}
            },
            users: {},
            groups: {},
            receivers: {},
            to: {},
            subject: '',
            message: ''
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadUsers();
        this.loadGroups();
    }

    componentDidMount() {
        this.mounted = true;

        let { groups, users } = this.state;
        let receivers = this.makeReceivers(groups, users);
        this.setState({ receivers });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadUsers() {
        this.api.getAccounts()
            .then(response => {
                if (this.mounted) {
                    let users = _.mapKeys(response.data, 'id');
                    let receivers = this.makeReceivers(this.state.groups, users);
                    this.setState({ users, receivers });
                }
            })
            .catch(error => {
                console.log('Emails.loadUsers', error);
            });
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                if (this.mounted) {
                    let groups = _.mapKeys(response.data, 'id');
                    let receivers = this.makeReceivers(groups, this.state.users);
                    this.setState({ groups, receivers });
                }
            })
            .catch(error => {
                console.log('Emails.loadGroups', error);
            });
    }

    makeReceivers(groups, users) {
        let result = {};

        let all = {
            type: RT_ALL,
            id: '0',
            key: RT_ALL + '-0',
            text: this.context.translate('Emails.All')
        };
        result[all.key] = all;

        _.forEach(groups, o => {
            let item = {
                type: RT_GROUP,
                id: o.id,
                key: RT_GROUP + '-' + o.id,
                text: o.name
            };
            result[item.key] = item;
        });

        _.forEach(users, o => {
            let item = {
                type: RT_USER,
                id: o.id,
                key: RT_USER + '-' + o.id,
                text: o.name
            };
            result[item.key] = item;
        });

        return result;
    }

    typeIco(type) {
        switch (type) {
            case RT_GROUP:
                return 'fa-users';
            case RT_USER:
                return 'fa-user';
            default:
                return 'fa-envelope';
        }
    }

    closeModal() {
        this.setState({ modal: { ...this.state.modal, open: false } });
    }

    setReceiver(item) {
        let { to } = this.state.modal;

        let t = { ...to };
        if (item.key in to) {
            delete t[item.key];
        } else {
            t[item.key] = item;
        }
        this.setState({ modal: { ...this.state.modal, to: t } });
    }

    submitSelectTo() {
        let { modal } = this.state;

        this.setState({ modal: { ...modal, open: false }, to: modal.to });
    }

    send() {
        if (this.validate()) {
            let { to, subject, message } = this.state;
            this.api.sendEmail(_.map(to), subject, message)
                .then(response => {
                    if (this.mounted) {
                        toastr.success(this.context.translate('Emails.SendSuccess'));
                        this.clear();
                    }
                    this.closeModal();
                })
                .catch(error => {
                    console.log('Emails.send error send email', error);
                    if (this.mounted) {
                        toastr.error(error);
                    }
                });
        }
    }

    validate() {
        return true;
    }

    clear() {
        this.setState({ to: {}, subject: '', message: '' });
    }

    render() {
        let { modal, receivers, to, subject, message } = this.state;

        receivers = arraySort(_.map(receivers), 'type', 'text')
        to = arraySort(_.map(to), 'type', 'text')

        return (
            <div className="page-container emails">
                <div className="email-definition">
                    <div className="to-part">
                        <div className="label">{this.context.translate('Emails.To')}</div>
                        <div className="to-view">
                            {!to.length &&
                                <span className="placeholder">{this.context.translate('Emails.SelectTo') + '...'}</span>
                            }
                            {to.map(o => (
                                <div
                                    key={o.key}
                                    className="receiver"
                                >
                                    <div className="type"><i className={classnames("fa", { 'fa-envelope': o.type === RT_ALL, 'fa-user': o.type === RT_USER, 'fa-users': o.type === RT_GROUP })} aria-hidden="true"></i></div>
                                    <div className="name">{o.text}</div>
                                </div>
                            ))}
                        </div>
                        <div
                            className="btn"
                            onClick={() => this.setState({ modal: { ...modal, open: true, to: { ...this.state.to } } })}
                        ><i className="fa fa-folder-open-o" aria-hidden="true"></i></div>
                    </div>
                    <div className="subject-part">
                        <div className="label">{this.context.translate('Emails.Subject')}</div>
                        <input
                            className="form-control value"
                            type="text"
                            value={subject}
                            onChange={e => this.setState({ subject: e.target.value })}
                        />
                    </div>
                    <div className="message-part">
                        <div className="label">{this.context.translate('Emails.Message')}</div>
                        <textarea
                            className="form-control"
                            cols="40"
                            rows="5"
                            value={message}
                            onChange={e => this.setState({ message: e.target.value })}
                        ></textarea>
                    </div>
                    <div className="button-part">
                        <div
                            className="btn btn-light"
                            onClick={() => this.clear()}
                        >{this.context.translate('Btn.Cancel')}</div>
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.send()}
                        >{this.context.translate('Btn.Send')}</div>
                    </div>
                </div>

                {modal.open &&
                    <Modal
                        modalClass="select-to-modal"
                        onClose={() => this.closeModal()}
                        onSubmit={() => this.submitSelectTo()}
                        title={this.context.translate('Emails.SelectTo')}
                        show={true}
                        closeOnClick={true}
                    >
                        <div>
                            <fieldset>
                                <div className="form-group">
                                    <div className="receivers">
                                        {receivers.map(o => (
                                            <div
                                                key={o.key}
                                                className={classnames("receiver", { selected: (o.key in modal.to) })}
                                                onClick={() => this.setReceiver(o)}
                                            >
                                                <div className="type"><i className={classnames("fa", { 'fa-envelope': o.type === RT_ALL, 'fa-user': o.type === RT_USER, 'fa-users': o.type === RT_GROUP })} aria-hidden="true"></i></div>
                                                <div className="name">{o.text}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="form-group buttons">
                                    <button
                                        className="btn btn-light"
                                        onClick={() => this.closeModal()}
                                    >{this.context.translate('Btn.Cancel')}</button>
                                    <button
                                        className="btn btn-imsp"
                                        onClick={() => this.submitSelectTo()}
                                    >{this.context.translate('Btn.Select')}</button>
                                </div>
                            </fieldset>
                        </div>
                    </Modal>
                }
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Emails);
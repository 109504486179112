import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import Period from '../../../components/Period';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import { toStandardDmySpan, toModalDate } from '../../../utils/date';

import './payment.scss';
import ImportResult from '../../../components/importResult/ImportResult';
import { PT_DEPOSITE, PT_PAYMENT, MT_MENU1, MT_SNACK, MT_MENU3, MT_MENU2 } from '../../../helpers/Constants';


class Payment extends React.Component {
    static propTypes = {
        profile: PropTypes.bool
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.api = new ApiService();

        let getPeriod = value => { return new Date(value.getFullYear(), value.getMonth(), 1); };
        this.state = {
            period: {
                date: getPeriod(new Date())
            },
            diners: {
                data: {},
                pending: false,
                error: null,
                selected: ''
            },
            history: {
                data: {}
            },
            modal: {
                open: false,
                id: '',
                time: '',
                diner: '',
                type: 2,
                description: '',
                amount: '',
                note: '',
                mealType: ''
            },
            importResult: {
                show: false,
                data: {}
            },
            typeFilter: '',
            selected: ''
        }
        this.mounted = false;

        this.types = {
            1: 'Payment.TypePayment',
            2: 'Payment.TypeDeposite'
        };
        this.typeFilterOptions = [
            { value: '', label: 'Codelist.All' },
            { value: '1', label: 'Payment.TypePayment' },
            { value: '2', label: 'Payment.TypeDeposite' }
        ]
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.profile) {
            this.loadDiners();
            this.loadHistories();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profile && !this.props.profile) {
            this.loadDiners();
            this.loadHistories();
        }
    }

    loadHistories() {
        this.api.getHistory(this.state.period.date)
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    this.setState({ history: { data } });
                }
            })
            .catch(error => {
                console.log('Payment.loadHistories error', error);
            })
    }

    loadDiners() {
        this.setState({ diners: { ...this.state.diners, pending: true } });
        this.api.getDiners()
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    this.setState({
                        diners: {
                            ...this.state.diners,
                            pending: false,
                            data
                        }
                    });
                }
            })
            .catch(error => {
                console.log('Payment.loadDiners error', error);
                if (this.mounted) {
                    this.setState({
                        menu: {
                            ...this.state.diners,
                            pending: false,
                            error: error
                        }
                    });
                }
            })
    }

    onPeriodChange(period) {
        this.setState({ period: { date: period } }, () => {
            this.loadHistories();
        });
    }

    selectedDinerChanged(id) {
        let { diners } = this.state;
        this.setState({ diners: { ...diners, selected: id } }, () => {
            this.loadHistories();
        });
    }

    typeFilterChanged(typeFilter) {
        this.setState({ typeFilter });
    }

    add() {
        this.setState({
            modal: {
                ...this.state.modal,
                open: true,
                id: '',
                time: toModalDate(new Date()),
                amount: '',
                description: '',
                diner: this.state.diners.selected,
                type: 2,
                mealType: 1,
                note: ''
            }
        });
    }

    update(id) {
        let { selected, history, modal } = this.state;

        id = id || selected;
        if (!id) {
            return;
        }

        let item = history.data[selected]

        this.setState({
            modal: {
                ...modal,
                open: true,
                id,
                time: toModalDate(item.time),
                amount: item.amount,
                description: item.description || '',
                diner: item.dinerId,
                type: item.type,
                mealType: item.mealType || '',
                note: item.note
            }
        });
    }

    closeModal() {
        this.setState({ modal: { ...this.state.modal, open: false } });
    }

    submitModal() {
        if (this.validateModal()) {
            let { id, time, diner, type, description, amount, mealType, note } = this.state.modal;
            if (type === PT_PAYMENT) {
                description = null;
            } else {
                mealType = null;
            }
            note = null;

            if (id) {
                this.api.updateHistory(id, time, diner, type, description, amount, mealType, note)
                    .then(response => {
                        this.loadHistories();
                        this.closeModal();
                        toastr.success(this.context.translate('Payment.SaveSuccess'));
                    })
                    .catch(error => {
                        console.log('Payment.submitModal error save history', error);
                        toastr.error(error);
                    });
            } else {
                this.api.addHistory(time, diner, type, description, amount, mealType, note)
                    .then(response => {
                        this.loadHistories();
                        this.closeModal();
                        toastr.success(this.context.translate('Payment.SaveSuccess'));
                    })
                    .catch(error => {
                        console.log('Payment.submitModal error save history', error);
                        toastr.error(error);
                    });
            }
        }
    }

    validateModal() {
        return true;
    }

    import(file) {
        if (!file) {
            return;
        }

        let data = new FormData();
        data.append('files', file);

        let _self = this;
        this.api.importTransactions(data)
            .then(response => {
                if (_self.mounted) {
                    _self.setState({ importResult: { show: true, data: response.data } });
                    _self.loadHistories();
                    _self.fileInput.value = "";
                }
            })
            .catch(error => {
                console.log('Payment.import error import transactions', error);
                toastr.error(error);
            });
    }

    delete(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        if (window.confirm(this.context.translate('Payment.ConfirmDelete'))) {
            let _self = this;
            this.api.deletePayment(id)
                .then(response => {
                    if (_self.mounted) {
                        toastr.success('Záznam byl úspěšně smazán.');
                        let data = { ..._self.state.history.data };
                        delete data[id];
                        _self.setState({ history: { data } });
                        _self.loadHistories();
                    }
                })
                .catch(error => {
                    console.log('Payment.delete error delete record', error);
                    toastr.error(error);
                });
        }
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? null : key });
    }

    getSumaPayment(items) {
        return (items || []).reduce((r, o) => {
            if (o.type === 1) {
                return r + o.amount;
            }
            return r;
        }, 0);
    }

    getSumaDeposit(items) {
        return (items || []).reduce((r, o) => {
            if (o.type === 2) {
                return r + o.amount;
            }
            return r;
        }, 0);
    }

    render() {
        let { diners, history, period, modal, importResult, typeFilter, selected } = this.state;

        let dinnerArray = arraySort(_.map(diners.data), 'name');
        let dinerItems = [{ id: '', name: this.context.translate('Codelist.All') }, ...dinnerArray];
        let modalDiners = [{ id: '', name: this.context.translate('Codelist.ChoseDiner') }, ...dinnerArray];
        let items = arraySort(_.map(history.data), { prop: 'time', order: 'asc' });
        if (diners.selected) {
            items = items.filter(o => o.dinerId === diners.selected);
        }
        if (typeFilter) {
            items = items.filter(o => o.type === typeFilter);
        }
        let deleteBtnDisabled = !selected;
        let updateBtnDisabled = !selected;

        let showDescription = modal.type === PT_DEPOSITE;
        let showMealType = modal.type === PT_PAYMENT;

        let sumaPayment = this.getSumaPayment(items);
        let sumaDeposit = this.getSumaDeposit(items);
        let balance = sumaDeposit - sumaPayment;

        return (
            <div className="page-container payment-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Period')}</label>
                            <Period
                                period={period.date}
                                onChange={p => this.onPeriodChange(p)}
                                allowWheel={true}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Diner')}</label>
                            <select
                                className="form-control diner-selector"
                                name="diner"
                                value={diners.selected}
                                onChange={e => this.selectedDinerChanged(e.target.value)}
                            >
                                {dinerItems.map(o => (
                                    <option key={o.id || 0} value={o.id}>{o.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Type')}</label>
                            <select
                                className="form-control"
                                name="type"
                                value={typeFilter}
                                onChange={e => this.typeFilterChanged(e.target.value)}
                            >
                                {this.typeFilterOptions.map(o => (
                                    <option key={o.value} value={o.value}>{this.context.translate(o.label)}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.add()}
                        >{this.context.translate('Btn.Add')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: updateBtnDisabled })}
                            onClick={() => this.update()}
                        >{this.context.translate('Btn.Update')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: deleteBtnDisabled })}
                            onClick={() => this.delete()}
                        >{this.context.translate('Btn.Delete')}</div>
                        <div
                            className="btn btn-imsp import-btn"
                        >
                            {this.context.translate('Payment.Import')}
                            <input
                                type={'file'}
                                onChange={e => this.import(e.target.files[0])}
                                ref={ref => this.fileInput = ref}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                        </div>
                        <div className="row-cont-right">
                            <a
                                className="link-button"
                                href="/doc/import-payments.xlsx"
                                download
                                target="_blank"
                            >{this.context.translate('Payment.ImportExample')}</a>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table history-table">
                        <colgroup>
                            <col className="col-time" />
                            <col className="col-diner" />
                            <col className="col-type" />
                            <col className="col-description" />
                            <col className="col-amount" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="ta-c">{this.context.translate('Payment.Time')}</th>
                                <th className="ta-c">{this.context.translate('Payment.Diner')}</th>
                                <th className="ta-c">{this.context.translate('Payment.Type')}</th>
                                <th className="ta-c">{this.context.translate('Payment.Description')}</th>
                                <th className="ta-c">{this.context.translate('Payment.Amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => {
                                let description = o.description;
                                if ((o.type === PT_PAYMENT) && o.mealType) {
                                    description = `${this.context.translate('MealType.' + o.mealType)}`;
                                }
                                let coef = o.type === PT_PAYMENT ? -1 : 1;
                                return (
                                    <tr
                                        key={o.id}
                                        className={classnames({ payment: o.type === PT_PAYMENT, deposite: o.type === PT_DEPOSITE, selected: selected === o.id })}
                                        onClick={() => this.select(o.id)}
                                    >
                                        <td className="ta-c">{toStandardDmySpan(o.time)}</td>
                                        <td>{(o.diner || {}).name}</td>
                                        <td>{this.context.translate(this.types[o.type])}</td>
                                        <td>{description}</td>
                                        <td className="ta-r">{o.amount * coef} {this.context.translate('Units.Money')}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-suma-payment" />
                            <col className="col-sume-deposit" />
                            <col className="col-balance" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="ta-r">{this.context.translate('Payment.SumaPayment')}</th>
                                <th className="ta-r">{this.context.translate('Payment.SumaDeposit')}</th>
                                <th className="ta-r">{this.context.translate('Payment.Bal')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="ta-r">{sumaPayment} {this.context.translate('Units.Money')}</td>
                                <td className="ta-r">{sumaDeposit} {this.context.translate('Units.Money')}</td>
                                <td className="ta-r">{balance} {this.context.translate('Units.Money')}</td>
                            </tr>
                        </tbody>
                    </table>

                    <Modal
                        modalClass="imsp-modal"
                        onClose={() => this.closeModal()}
                        onSubmit={() => this.submitModal()}
                        title={this.context.translate(`Payment.${modal.id ? 'EditTitle' : 'AddTitle'}`)}
                        show={modal.open}
                        closeOnClick={true}
                    >
                        <div>
                            <fieldset>
                                <div className="form-group">
                                    <label htmlFor="time">{this.context.translate('Payment.Time')}</label>
                                    <input
                                        className="form-control"
                                        name="time"
                                        type="text"
                                        autoFocus
                                        value={modal.time}
                                        onChange={e => this.setState({ modal: { ...modal, time: e.target.value } })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="diner">{this.context.translate('Payment.Diner')}</label>
                                    <select
                                        className="form-control"
                                        name="diner"
                                        value={modal.diner}
                                        onChange={e => this.setState({ modal: { ...modal, diner: e.target.value } })}
                                        disabled={!!modal.id}
                                    >
                                        {modalDiners.map(o => (
                                            <option key={o.id || 0} value={o.id}>{o.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="type">{this.context.translate('Payment.Type')}</label>
                                    <select
                                        className="form-control"
                                        name="type"
                                        value={modal.type}
                                        onChange={e => this.setState({ modal: { ...modal, type: e.target.value } })}
                                    >
                                        <option value={1}>{this.context.translate(this.types[1])}</option>
                                        <option value={2}>{this.context.translate(this.types[2])}</option>
                                    </select>
                                </div>
                                {showDescription &&
                                    <div className="form-group">
                                        <label htmlFor="description">{this.context.translate('Payment.Description')}</label>
                                        <input
                                            className="form-control"
                                            name="description"
                                            type="text"
                                            value={modal.description}
                                            onChange={e => this.setState({ modal: { ...modal, description: e.target.value } })}
                                        />
                                    </div>
                                }
                                {showMealType &&
                                    <div className="form-group">
                                        <label htmlFor="mealType">{this.context.translate('Payment.MealType')}</label>
                                        <select
                                            className="form-control"
                                            name="mealType"
                                            value={modal.mealType}
                                            onChange={e => this.setState({ modal: { ...modal, mealType: e.target.value } })}
                                        >
                                            <option value={MT_MENU1}>{this.context.translate('MealType.' + MT_MENU1)}</option>
                                            <option value={MT_MENU2}>{this.context.translate('MealType.' + MT_MENU2)}</option>
                                            <option value={MT_MENU3}>{this.context.translate('MealType.' + MT_MENU3)}</option>
                                            <option value={MT_SNACK}>{this.context.translate('MealType.' + MT_SNACK)}</option>
                                        </select>
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="amount">{this.context.translate('Payment.Amount')}</label>
                                    <input
                                        className="form-control"
                                        name="amount"
                                        type="text"
                                        value={modal.amount}
                                        onChange={e => this.setState({ modal: { ...modal, amount: e.target.value } })}
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => this.submitModal()}
                                    >{this.context.translate('Btn.Save')}</button>
                                    <button
                                        className="btn btn-light"
                                        onClick={() => this.closeModal()}
                                    >{this.context.translate('Btn.Cancel')}</button>
                                </div>
                            </fieldset>
                        </div>
                    </Modal>
                    {importResult.show &&
                        <ImportResult
                            data={importResult.data}
                            onClose={() => this.setState({ importResult: { ...importResult, show: false } })}
                        />
                    }
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        profile: state.profile.loaded
    }),
    {}
)(Payment);
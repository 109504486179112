import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { prevPeriod, nextPeriod } from '../utils/period';

import './period.scss';
import { toDate, prevYear, nextYear } from '../utils/date';

class Period extends React.Component {
    static propTypes = {
        period: PropTypes.object,
        disablePrev: PropTypes.bool,
        disableNext: PropTypes.bool,
        onChange: PropTypes.func,
        className: PropTypes.string,
        language: PropTypes.string.isRequired,
        allowWheel: PropTypes.bool
    }

    static defaultProps = {
        allowWheel: false
    };

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            period: props.period
        }

        this.delayChange = _.debounce(value => {
            if (props.onChange) {
                props.onChange(value);
            }
        }, 300);
    }

    onPrevPeriod() {
        if (!this.props.disablePrev && this.props.onChange) {
            let period = prevPeriod(this.state.period);
            this.setState({ period }, () => this.props.onChange(period));
        }
    }

    onNextPeriod() {
        if (!this.props.disableNext && this.props.onChange) {
            let period = nextPeriod(this.state.period);
            this.setState({ period }, () => this.props.onChange(period));
        }
    }

    onWheelMonth(e) {
        let { allowWheel } = this.props;

        if (allowWheel) {
            let { deltaY } = e; // deltaMode, deltaX, deltaZ, 
            // console.log('Period.onWheel', { deltaMode, deltaX, deltaY, deltaZ });

            if (deltaY > 0) {
                let period = prevPeriod(this.state.period);
                this.setState({ period }, () => this.delayChange(period));
            } else if (deltaY < 0) {
                let period = nextPeriod(this.state.period);
                this.setState({ period }, () => this.delayChange(period));
            }
        }
    }

    onWheelYear(e) {
        let { allowWheel } = this.props;

        if (allowWheel) {
            let { deltaY } = e; // deltaMode, deltaX, deltaZ, 
            // console.log('Period.onWheel', { deltaMode, deltaX, deltaY, deltaZ });

            if (deltaY > 0) {
                let period = prevYear(this.state.period);
                this.setState({ period }, () => this.delayChange(period));
            } else if (deltaY < 0) {
                let period = nextYear(this.state.period);
                this.setState({ period }, () => this.delayChange(period));
            }
        }
    }

    render() {
        let date = toDate(this.state.period);
        let month = this.context.translate('Months.Basic.' + (date.getMonth() + 1));
        let year = date.getFullYear();

        return (
            <div className={classnames("period", this.props.className)}>
                <div
                    className={classnames("btn", { disabled: this.props.disablePrev })}
                    onClick={() => this.onPrevPeriod()}
                ><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                <div className="label">
                    <span
                        className="label-item"
                        onWheel={e => this.onWheelMonth(e)}
                    >{month}</span>
                    <span
                        className="label-item"
                        onWheel={e => this.onWheelYear(e)}
                    >{year}</span>
                </div>
                <div
                    className={classnames("btn", { disabled: this.props.disableNext })}
                    onClick={() => this.onNextPeriod()}
                ><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
            </div>
        );
    }
};

export default connect(
    state => ({
        language: state.localize.language
    }),
    {}
)(Period);
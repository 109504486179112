const en = {
	"Account": {
		"Name": "Name",
		"Email": "E-mail",
		"Address": "Address",
		"AccNum": "Account number",
		"Diners": "Diners",
		"Roles": "Roles",
		"SetRole": "Set roles",
		"IsDiners": "Is diner",
		"SetAsDiner": "Set as diner",
		"WithoutDiner": "Without diner",
		"Delete": "Delete",
		"DeletedSuccessfuly": "Account successfuly deleted.",
		"ConfirmDelete": "Are you sure you want to delete the selected account?",
		"ConfirmForceDelete": "Data already exists for the selected account. Are you sure you want to delete it?"
	},
	"Alergen": {
		"Id": "ID",
		"Cs": "Czech",
		"En": "English",
		"ModalTitle": "Alergen",
		"SaveSuccess": "Alergen was saved successfuly.",
		"DeleteSuccess": "Alergen was deleted successfuly."
	},
	"Billing": {
		"User": "User",
		"Diner": "Diner",
		"CreditBefore": "Opening balance",
		"Menu": "Meals",
		"Snack": "Snacks",
		"Total": "Total",
		"Credit": "Closing balance",
		"Recalculate": "Recalculate",
		"ConfirmRecalculate": "Are you sure you want to recalculate billing for {period} and all subsequent billing?",
		"RecalculateSent": "Recalculation request sent successfully. Relax for a minute, then refresh the page.",
		"Suma": "Suma",
		"Balance": "Balance",
		"Deposit": "Deposits"
	},
	"Btn": {
        "Add": "Add",
        "Update": "Update",
        "Delete": "Delete",
		"Cancel": "Cancel",
		"Save": "Save",
		"Export": "Export",
		"Import": "Import",
		"Close": "Close",
		"Select": "Select",
		"Send": "Send",
		"Print": "Print"
	},
	"Codelist": {
		"All": "< all >",
		"ChoseDiner": "< chose diner >",
		"Yes": "Yes",
		"No": "No"
	},
	"Daily": {
		"Diner": "Diner",
		"Group": "Group"	
	},
    "Days": {
		"Basic": {
			"0": "Sunday",
			"1": "Monday",
			"2": "Tuesday",
			"3": "Wednesday",
			"4": "Thursday",
			"5": "Friday",
			"6": "Saturday"
		},
		"Short": {
			"0": "Sun",
			"1": "Mon",
			"2": "Tue",
			"3": "Wed",
			"4": "Thu",
			"5": "Fr",
			"6": "Sat"
		}
    },
	"Diner": {
		"Name": "Name",
		"Group": "Group",
		"Account": "Account",
		"UserDiner": "Is account",
		"NotPay": "All inclusive",
		"GlutenFree": "Gluten-free and eggless diet",
		"Move": "Move",
		"ModalTitle": "Diner",
		"MoveModalTitle": "Move diners",
		"MoveSubmit": "Move",
		"MoveSuccess": "Diners was moved successfuly.",
		"PaymentId": "Payment ID",
		"Balance": "Balance",
		"Unposted": "Unposted orders",
		"ConfirmDelete": "Are you sure you want to delete the selected diner?",
		"DeleteSuccess": "The diner has been successfully deleted.",
		"DeleteDependencies": "The diner you are about to delete already has data (orders, payments, billing). Are you sure you want to delete it with all data?",
		"Hidden": "Hide in orders",
		"From": "School year from",
		"To": "School year to"
	},
	"Emails": {
		"To": "To",
		"Subject": "Subject",
		"Message": "Message",
		"SelectTo": "Select reseivers",
		"All": "Everybody",
		"SendSuccess": "E-mail was sent successfuly."
	},
	"Filter": {
        "Period": "Period",
        "Diner": "Diner",
		"Group": "Group",
		"Date": "Date",
		"Title": "Filter",
		"AllInclusive": "All inclusive",
		"IsUser": "Is user",
		"Name": "Name",
		"Type": "Type",
		"From": "From",
		"To": "To",
		"NoGroup": "Without group"
	},
	"Group": {
		"Title": "Group",
		"Name": "Name",
		"Order": "Order in Month orders",
		"AddSuccess": "Group created successfuly.",
		"EditSuccess": "Group updated successfuly.",
		"DeleteConfirm": "Are you sure you want to delete the selected group?",
		"DeleteConfirmDependent": "The selected group is linked to other data (diners, students, courses). If the group is deleted, boarders and students would be removed from the group, and for courses, the group would be removed from the filter. Are you sure you want to delete the selected group?"
	},
    "Header": {
        "Login": "Login",
        "Register": "Register",
        "Menu": "Menu",
        "Payments": "Payments",
        "Logout": "Logout",
        "Administration": "Administration",
        "Accounts": "User accounts",
        "Diners": "Diners",
        "Groups": "Groups",
        "Lunch": "Menu",
		"Profile": "Profile",
		"AdminPayments": "Payments",
		"Billing": "Billing",
		"Alergens": "Alergens",
		"Settings": "System settings",
		"Reports": "Reports",
		"OrderSummary": "Orders counts",
		"OrderDay": "Diners orders",
		"OrderMonth": "Month orders",
		"Emails": "Send e-mail",
		"About": {
			"Title": "About",
			"Name": "Name",
			"App": "IMSP Lunch system",
			"Version": "Version",
			"Copyright": "© 2019 Copyright by IMSP. All right reserved.",
			"By": "Created by"
		},
		"Notice": "Sent notices",
		"OrderAdmin": "Orders"
    },
	"ImportResult": {
		"Title": "Import result"
	},
    "Lunch": {
        "SaveSuccess": "Menu was saved successfuly.",
        "ImportSuccess": "{{count}} records was imported successfuly.",
        "ImportError": "{{count}} records was succesfuly imported, {{errCount}} records finished with error:</br>{{errors}}",
        "Period": "Period",
        "Lang": "Language",
        "DownloadImport": "Download example file",
        "ColDate": "Date",
        "ColLang": "Language",
        "ColSoup": "Soup",
		"RemoveSuccess": "Menu successfully deleted.",
		"ConfirmRemove": "Are you sure you want to delete the selected record?"
    },
    "LunchMenu": {
		"NoDiner": "Welcome to the IMSP Lunch System. You have no diners set up for your account yet, so you can't place an order.",
        "Balance": "Account balance",
        "UnpostedOrders": "Unposted orders",
        "PaymentInfo": "Show payment information",
        "Alergens": "Alergens",
		"NoAlergens": "Alergens are not specified",
		"AlergensNotParsed": "Alergens was not recognized",
		"Modal": {
			"Title": "Payment informations",
			"Number": "Account number",
			"Symbol": "Variable symbol",
			"Amount": "Amount",
			"Note": "The payment amount is only a guideline, calculated based on your account balance and orders in a given month.",
			"Close": "Close",
			"Ss": "Specifický symbol",
			"NewNumber": "Please note that our bank account number has changed and update your records accordingly."
		},
		"ExportMenu": "Export menu",
		"MissingBillingInfo": {
			"Part1": "Dear user, You do not have filled in billing information. Please set them up in the ",
			"Part2": " section. If you don't have your billing information filled in, you won't be allowed to order lunches."
		},
		"LimitExceeded": "Maximal debit limit was exceeded.",
		"HidePast": "Hide old"
    },
	"MealType": {
		"0": "Soup",
		"1": "Meat meal",
		"2": "Vegetarian meal",
		"3": "Gluten-and/or Egg-gree meal",
		"4": "Snack"
	},
	"Monthly": {
		"Diner": "Diner",
		"Group": "Group",
		"Print": {
			"Title": "Monthly order overview",
			"All": "all"
		}
	},
    "Months": {
		"Basic": {
			"1": "January",
			"2": "February",
			"3": "March",
			"4": "April",
			"5": "May",
			"6": "June",
			"7": "July",
			"8": "August",
			"9": "September",
			"10": "October",
			"11": "November",
			"12": "December"
		},
		"OfDate": {
			"1": "January",
			"2": "February",
			"3": "March",
			"4": "April",
			"5": "May",
			"6": "June",
			"7": "July",
			"8": "August",
			"9": "September",
			"10": "October",
			"11": "November",
			"12": "December"
		},
		"Short": {
			"1": "Jan",
			"2": "Feb",
			"3": "Mar",
			"4": "Apr",
			"5": "May",
			"6": "Jun",
			"7": "Jul",
			"8": "Aug",
			"9": "Sept",
			"10": "Oct",
			"11": "Nov",
			"12": "Dec"
		}
	},
	"Notice": {
		"Time": "Send time",
		"Period": "Period",
		"Result": "Result",
		"CountText": "Number of debtors",
		"Ok": "Everything OK",
		"Errors": "Errors",
		"Diner": "Diner",
		"Balance": "Balance",
		"Error": "Error"
	},
	"OrderView": {
		"Date": "Date",
		"Count": "Count"
	},
    "Payment": {
		"TypePayment": "Payment",
		"TypeDeposite": "Deposite",
		"Time": "Time",
		"Diner": "Diner",
		"Type": "Type",
		"Description": "Description",
		"Amount": "Amount",
		"TotalPayment": "Total payments",
		"AddTitle": "New record",
        "ImportSuccess": "{{count}} records was imported successfuly.",
        "ImportError": "{{count}} records was succesfuly imported, {{errCount}} records finished with error:</br>{{errors}}",
		"Balance": "Account balance",
		"TotalDeposite": "Total deposites",
		"ImportExample": "Example import file",
		"Import": "Import deposites",
		"ConfirmDelete": "Are you sure you want to delete the selected record?",
		"MealType": "Meal",
		"EditTitle": "Edit record",
		"SumaPayment": "Suma payments",
		"SumaDeposit": "Suma deposites",
		"Bal": "Balance"
	},
	"Profile": {
		"Profile": "Profile",
		"Name": "Name",
		"Email": "E-mail",
		"Invoice": "Billing information",
		"Address": "Address",
		"Account": "Account number",
		"Ico": "Tax ID (IČO)",
		"ProfileSuccess": "Profile was saved.",
		"BillingSuccess": "Billing inforations saved."
	},
	"Settings": {
		"MealPrice": "Meal prices",
		"Other": "Other parameters",
		"AccountNumber": "Account number",
		"DebitLimit": "Debit limit",
		"MealPriceSuccess": "Meal prices was saved successfuly.",
		"OtherSuccess": "Other parameters was saved successfuly.",
		"SpecificSymbol": "Specific symbol",
		"SendingOrders": "Emailing lunch orders",
		"SoEnabled": "Enabled",
		"SoTo": "To",
		"SoCc": "Cc",
		"SendOrdersSuccess": "Emailing lunch orders saved successfuly.",
		"SendBalanceWarning": "Notifications of negative account status",
		"SendNoticeToOrder": "Notifications to order lunches",
		"SbwEnabled": "Enabled",
		"BalanceWarningDay": "Date of notification",
		"SendNoticeToOrderDayBefore": "Days before lunch period",
		"SendBalanceWarningSuccess": "Notifications of negative account status saved successfully.",
		"SendNoticeToOrderSuccess": "Notifications to order lunches saved successfully."
	},
    "Units": {
        "Money": "CZK"
    },
	"Order": {
		"Date": "Date",
		"Diner": "Diner",
		"Lunch": "Lunch",
		"Snack": "Snack",
		"ConfirmDelete": "Are you sure you want to delete the selected records? Charged orders will be deleted including payments. If orders from the previous period are deleted, it is necessary to run the billing recalculation.",
		"DeletedSuccessfuly": "Orders successfully deleted."
	},
	"General": {
		"SchoolYear": "School year"
	}
};

export default en;
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { push } from "connected-react-router";
import { Route, Switch } from 'react-router'

import userManager from "../utils/userManager";
import Header from './header/Header';
import LunchMenu from '../containers/user/lunchMenu/LunchMenu';
import { AuthService } from '../services/AuthService';
import AdminContent from '../containers/admin/AdminContent';
import Payment from '../containers/user/payment/Payment';
import Profile from '../containers/user/profile/Profile';
import ViewContent from '../containers/view/ViewContent';

import './appContent.css';


class AppContent extends React.Component {
    static propTypes = {
        location: PropTypes.object,
        language: PropTypes.string.isRequired,
        push: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.authService = new AuthService();
        this.state = { user: {}, api: {} };
        this.mounted = true;
        this.query = parse(props.location.search.substr(1));
        // console.log('AppContent', { query: this.query, location: props.location });
    }

    componentDidMount() {
        this.getUser();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getUser = () => {
        this.authService.getUser().then(user => {
            if (user) {
                if (this.query.logged) {
                    this.props.push(this.props.location.pathname);
                }
            } else {
                if (this.query.logged) {
                    userManager.signinRedirect({
                        ui_locales: this.props.language
                    });
                } else {
                    console.log('You are not logged in.');
                }
            }

            if (this.mounted) {
                this.setState({ user });
            }
        });
    };

    login() {
        userManager.signinRedirect({
            ui_locales: this.props.language
        });
    };

    logout() {
        userManager.signoutRedirect();
    };

    render() {
        // console.log('AppContent.render');
        let print = this.props.location.pathname.startsWith('/view/monthly/print');
        let showHeader = !print;
        return (
            <div className="app-content-container">
                {showHeader &&
                    <Header
                        user={this.state.user}
                        login={() => this.login()}
                        logout={() => this.logout()}
                    />
                }
                <div className={classnames("app-page-container", { print })}>
                    <Switch>
                        <Route path="/admin" component={AdminContent} />
                        <Route path="/view" component={ViewContent} />
                        <Route exact path="/profile" component={Profile} />
                        <Route exact path="/history" component={Payment} />
                        <Route path="/" component={LunchMenu} />
                        <Route component={LunchMenu} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        location: state.router.location,
        language: state.localize.language
    }), {
    push
})(AppContent);
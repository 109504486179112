import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import { toPaymentDateTime, toDate } from '../../../utils/date';

import './notice.scss';


class Notice extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            notices: {},
            selected: ''
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadNotice();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadNotice() {
        this.api.getBalanceNotices()
            .then(response => {
                let notices = _.mapKeys(response.data, 'id');
                if (this.mounted) {
                    this.setState({ notices });
                }
            })
            .catch(error => {
                console.log('Diners.loadGroups', error);
            });
    }

    getNotices(noticeDict) {
        let result = arraySort(_.map(noticeDict), { prop: 'time', order: 'desc' });
        return result;
    }

    toResultPreview(resultJson) {
        var result = JSON.parse(resultJson);
        let countText = this.context.translate('Notice.CountText');
        let errors = this.context.translate('Notice.Ok');
        if (_.some(result.Diners, o => o.Result !== 'success')) {
            let errorText = this.context.translate('Notice.Errors');
            errors = `${errorText}: ${result.Diners.filter(o => o.Result !== 'success').length}`;
        }
        return `${countText}: ${result.Count}, ${errors}`;
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? null : key });
    }

    render() {
        let { selected } = this.state;
        let notices = this.getNotices(this.state.notices);
        
        let resultItems = [];
        if (this.state.notices && selected && this.state.notices[selected] && this.state.notices[selected].result) {
            resultItems = JSON.parse(this.state.notices[selected].result).Diners;
        }

        return (
            <div className="page-container notice-page">
                <div className="page-container">
                    <div className="preview-part">
                        <table className="imsp-table">
                            <colgroup>
                                <col className="col-time" />
                                <col className="col-period" />
                                <col className="col-result" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="col">{this.context.translate('Notice.Time')}</th>
                                    <th className="col">{this.context.translate('Notice.Period')}</th>
                                    <th className="col">{this.context.translate('Notice.Result')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notices.map(o => {
                                    let periodDate = toDate(o.period);
                                    return (
                                        <tr
                                            key={o.id}
                                            className={classnames({ selected: selected === o.id })}
                                            onClick={() => this.select(o.id)}
                                        >
                                            <td>{toPaymentDateTime(o.time)}</td>
                                            <td>{`${this.context.translate('Months.Basic.' + (periodDate.getMonth() + 1))} ${periodDate.getFullYear()}`}</td>
                                            <td>{this.toResultPreview(o.result)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="detail-part">
                        <table className="imsp-table">
                            <colgroup>
                                <col className="col-diner" />
                                <col className="col-balance" />
                                <col className="col-result" />
                                <col className="col-error" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="col">{this.context.translate('Notice.Diner')}</th>
                                    <th className="col">{this.context.translate('Notice.Balance')}</th>
                                    <th className="col">{this.context.translate('Notice.Result')}</th>
                                    <th className="col">{this.context.translate('Notice.Error')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {resultItems.map(o => {
                                    return (
                                        <tr
                                            key={o.DinerId}
                                            // className={classnames({ selected: selected === o.id })}
                                            // onClick={() => this.select(o.id)}
                                        >
                                            <td>{o.DinerName}</td>
                                            <td>{`${o.Balance} ${this.context.translate('Units.Money')}`}</td>
                                            <td>{o.Result}</td>
                                            <td>{o.Error}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Notice);
import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";

import Period from '../../../components/Period';
import { arraySort } from '../../../utils/sort';
import { initPeriod, nextPeriod } from '../../../utils/period';
import { nextDay, toDate, toPeriodDate } from '../../../utils/date';
import { ApiService } from '../../../services/ApiService';

import './monthly.scss';
import schoolYear from '../../../utils/schoolYear';


class Monthly extends React.Component {
    static propTypes = {
        push: PropTypes.func.isRequired
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            filter: {
                period: initPeriod(),
                group: ''
            },
            groups: {},
            orders: [],
            diners: {}
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadDiners();
        this.loadGroups();
        this.loadOrders();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadDiners() {
        let { filter } = this.state;

        this.api.getDiners({ year: schoolYear(filter.period).year() })
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    this.setState({ diners: data });
                }
            })
            .catch(error => {
                console.log('Monthly.loadDiners', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            });
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                if (this.mounted) {
                    let groups = _.mapKeys(response.data, 'id');
                    this.setState({ groups });
                }
            })
            .catch(error => {
                console.log('Monthly.loadGroups', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            });
    }

    loadOrders() {
        this.api.getMonthlyOrders(this.state.filter.period)
            .then(response => {
                if (this.mounted) {
                    this.setState({ orders: response.data });
                }
            })
            .catch(error => {
                console.log('Monthly.loadOrders error', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            })
    }

    onPeriodChange(period) {
        this.setState({ filter: { ...this.state.filter, period } }, () => {
            this.loadOrders();
            this.loadDiners();
        });
    }

    export() {
        let { period, group } = this.state.filter;
        this.api.exportMonthOrders(period, group)
            .then(response => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `orders-monthly-${toPeriodDate(period)}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.log('Monthly.export error', error);
            })
    }

    getDays() {
        let { period } = this.state.filter;

        let result = [];
        let nextPeriodTime = nextPeriod(period).getTime();
        for (let d = new Date(period.getTime()); d.getTime() < nextPeriodTime; d = nextDay(d)) {
            if (d.getDay() !== 0 && d.getDay() !== 6) {
                result.push(d);
            }
        }
        return result;
    }

    render() {
        let { filter, groups, orders, diners } = this.state;
        let { period, group } = filter;

        let days = this.getDays();
        let groupList = [{ id: '', name: this.context.translate('Codelist.All') }, ...arraySort(_.map(groups), 'name')];

        let items = { ...diners };
        Object.keys(items).forEach(o => {
            items[o] = {
                ...items[o],
                groupName: (groups[items[o].groupId] || {}).name,
                groupOrder: (groups[items[o].groupId] || {}).monthOrdersOrder || 0
            };
            days.forEach(d => items[o][d.getDate()] = '');
        })
        console.log('Monthly.render', items);
        orders.forEach(o => {
            let meals = [];
            if (o.menu) {
                if (o.menu === 1) {
                    meals.push('M');
                } else if (o.menu === 2) {
                    meals.push('V');
                } else {
                    meals.push(`${o.menu}`);
                }
            }
            if (o.snack) {
                meals.push('S');
            }
            if (o.dinerId in items)
                items[o.dinerId][toDate(o.date).getDate()] = meals.join(' ');
        });
        items = arraySort(_.map(items), 'groupOrder', 'groupName', 'name');
        items = items.filter(o => !o.hidden);
        if (group) {
            items = items.filter(o => o.groupId === group);
        }

        let groupParam = group ? `&group=${group}` : '';
        let printUrl = `/view/monthly/print?period=${toPeriodDate(period)}${groupParam}`;

        return (
            <div className="page-container month-order-view-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Period')}</label>
                            <Period
                                period={period}
                                onChange={p => this.onPeriodChange(p)}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Group')}</label>
                            <select
                                className="form-control"
                                value={group}
                                onChange={e => this.setState({ filter: { ...filter, group: e.target.value } })}
                            >
                                {groupList.map(o => (
                                    <option key={o.id || 0} value={o.id}>{o.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.export()}
                        >{this.context.translate('Btn.Export')}</div>
                        <a
                            className="btn btn-imsp"
                            href={printUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >{this.context.translate('Btn.Print')}</a>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table" ref={o => (this.printRef = o)}>
                        <colgroup>
                            <col className="col-diner" />
                            <col className="col-group" />
                            {days.map(o => (
                                <col className="col-day" key={o.getTime()} />
                            ))}
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="col">{this.context.translate('Monthly.Diner')}</th>
                                <th className="col">{this.context.translate('Monthly.Group')}</th>
                                {days.map(o => (
                                    <th className="col ta-c" key={o.getTime()}>{o.getDate()}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => (
                                <tr
                                    key={o.id}
                                // className={classnames({ selected: this.state.selected === o.id })}
                                // onClick={() => this.select(o.id)}
                                >
                                    <td>{o.name}</td>
                                    <td>{o.groupName}</td>
                                    {days.map(d => (
                                        <td className="order ta-c" key={d.getTime()}>{o[d.getDate()]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {
        push
    }
)(Monthly);
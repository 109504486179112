import _ from 'lodash';
import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from "react-redux";
import { nextPeriod } from '../../../utils/period';
import { ApiService } from '../../../services/ApiService';
import { parse } from 'qs';
import { parseDate, nextDay, toDate } from '../../../utils/date';
import { arraySort } from '../../../utils/sort';
import schoolYear from '../../../utils/schoolYear';

class MonthlyPrint extends React.Component {
    static propTypes = {
        location: PropTypes.object
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.query = parse(props.location.search.substr(1));
        this.state = {
            filter: {
                period: new Date(parseDate(this.query.period + '-01')),
                group: this.query.group || ''
            },
            groups: {},
            orders: [],
            diners: {},
            groupsLoaded: false,
            ordersLoaded: false,
            dinersLoaded: false
        };
        console.log('state', this.state);
        this.mounted = false;
        this.api = new ApiService();
    }
    state = {
        loaded: false
    }

    componentWillMount() {
        this.loadDiners();
        this.loadGroups();
        this.loadOrders();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        if (this.state.groupsLoaded && this.state.ordersLoaded && this.state.dinersLoaded) {
            this.runPrint();
        }
    }

    loadDiners() {
        let { filter } = this.state;

        this.api.getDiners({ year: schoolYear(filter.period).year() })
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    this.setState({ diners: data, dinersLoaded: true });
                }
            })
            .catch(error => {
                console.log('Monthly.loadDiners', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            });
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                if (this.mounted) {
                    let groups = _.mapKeys(response.data, 'id');
                    this.setState({ groups, groupsLoaded: true });
                }
            })
            .catch(error => {
                console.log('Monthly.loadGroups', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            });
    }

    loadOrders() {
        this.api.getMonthlyOrders(this.state.filter.period)
            .then(response => {
                if (this.mounted) {
                    this.setState({ orders: response.data, ordersLoaded: true });
                }
            })
            .catch(error => {
                console.log('Monthly.loadOrders error', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            })
    }

    loadData() {
        setTimeout(() => {
            this.setState({ loaded: true });
        }, 1000);
    }

    runPrint() {
        console.log('run print');

        if (!this.state.printShown) {

            if (this.mounted) {
                this.setState({
                    printShown: true
                });
            }

            setTimeout(() => {
                window.print();

                setTimeout(() => {
                    window.close();
                }, 50);
            }, 50);
        }
    }

    landscapeOrientation() {
        return (
            <style type="text/css">
                {"@media print{@page {size: landscape; margin: 4mm; max-width: 100%;}}"}
            </style>
        );
    };

    getDays() {
        let { period } = this.state.filter;

        let result = [];
        let nextPeriodTime = nextPeriod(period).getTime();
        for (let d = new Date(period.getTime()); d.getTime() < nextPeriodTime; d = nextDay(d)) {
            if (d.getDay() !== 0 && d.getDay() !== 6) {
                result.push(d);
            }
        }
        return result;
    }

    render() {
        let { filter, groups, orders, diners } = this.state;
        let { period, group } = filter;

        let days = this.getDays();

        let items = { ...diners };
        Object.keys(items).forEach(o => {
            items[o] = {
                ...items[o],
                groupName: (groups[items[o].groupId] || {}).name,
                groupOrder: (groups[items[o].groupId] || {}).monthOrdersOrder || 0
            };
            days.forEach(d => items[o][d.getDate()] = '');
        })
        orders.forEach(o => {
            let meals = [];
            if (o.menu) {
                if (o.menu === 1) {
                    meals.push('M');
                } else if (o.menu === 2) {
                    meals.push('V');
                } else {
                    meals.push(`${o.menu}`);
                }
            }
            if (o.snack) {
                meals.push('S');
            }
            items[o.dinerId][toDate(o.date).getDate()] = meals.join(' ');
        });
        items = arraySort(_.map(items), 'groupOrder', 'groupName', 'name');
        items = items.filter(o => !o.hidden);
        if (group) {
            items = items.filter(o => o.groupId === group);
        }

        let date = toDate(period);
        let dateText = `${this.context.translate('Months.Basic.' + (date.getMonth() + 1))} ${date.getFullYear()}`;
        let groupText = group ? (groups[group] || {}).name : this.context.translate('Monthly.Print.All');

        return (
            <div className="monthly-print">
                {this.landscapeOrientation()}
                <div className="header">
                    <div className="title">{this.context.translate('Monthly.Print.Title')}</div>
                    <div className="filter">
                        <div className="period">
                            <div className="label">{this.context.translate('Filter.Period')}:</div>
                            <div className="value">{dateText}</div>
                        </div>
                        <div className="group">
                            <div className="label">{this.context.translate('Filter.Group')}:</div>
                            <div className="value">{groupText}</div>
                        </div>
                    </div>
                </div>
                <table className="imsp-table">
                    <colgroup>
                        <col className="col-diner" />
                        <col className="col-group" />
                        {days.map(o => (
                            <col className="col-day" key={o.getTime()} />
                        ))}
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="col">{this.context.translate('Monthly.Diner')}</th>
                            <th className="col">{this.context.translate('Monthly.Group')}</th>
                            {days.map(o => (
                                <th className="col ta-c" key={o.getTime()}>{o.getDate()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(o => (
                            <tr
                                key={o.id}
                            // className={classnames({ selected: this.state.selected === o.id })}
                            // onClick={() => this.select(o.id)}
                            >
                                <td>{o.name}</td>
                                <td>{o.groupName}</td>
                                {days.map(d => (
                                    <td className="order ta-c" key={d.getTime()}>{o[d.getDate()]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default connect(
    state => ({
        location: state.router.location
    }),
    {}
)(MonthlyPrint);
import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from '../Modal';

import './importResult.scss';


const ImportResult = (props, context) => {
    let items = ((props.data || {}).results || []).map(o => ({ ...o, label: o.row })).reduce((r, o) => {
        if (r.prev && r.prev.row && r.prev.type === o.type && r.prev.description === o.description) {
            r.prev.label = `${r.prev.row} - ${o.row}`;
        } else {
            let item = { ...o, label: o.row };
            r.prev = item;
            r.res.push(item);
        }
        return r;
    }, { prev: null, res: [] }).res;
    // console.log('ImportResult', { data: props.data });
    return (
        <Modal
            modalClass="imsp-modal"
            onClose={() => props.onClose()}
            onSubmit={() => props.onClose()}
            title={context.translate('ImportResult.Title')}
            show={true}
            closeOnClick={true}
        >
            <div className="import-result-container">
                <div className="item=list">
                    {items.map(o => (
                        <div className={classnames("import-result-item", o.type)}>
                            {o.row &&
                                <div className="label">{o.label}</div>
                            }
                            <div className="description">{o.description}</div>
                        </div>
                    ))}
                </div>
                <div className="buttons">
                    <button
                        className="btn btn-imsp"
                        onClick={() => props.onClose()}
                    >{context.translate('Btn.Close')}</button>
                </div>
            </div>
        </Modal>
    );
}

ImportResult.propType = {
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired
}

ImportResult.contextTypes = {
    translate: PropTypes.func.isRequired
};

export default ImportResult;
import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Period from '../../../components/Period';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import { toStandardDmySpan } from '../../../utils/date';
import { PT_DEPOSITE, PT_PAYMENT } from '../../../helpers/Constants';

import './payment.scss';


class Payment extends React.Component {
    static propTypes = {
        profile: PropTypes.bool
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.apiService = new ApiService();

        let getPeriod = value => { return new Date(value.getFullYear(), value.getMonth(), 1); };
        this.state = {
            period: {
                date: getPeriod(new Date())
            },
            diners: {
                data: {},
                pending: false,
                error: null,
                self: null,
                selected: ''
            },
            history: {
                data: {}
            }
        }
        this.mounted = false;

        this.monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
        this.types = {
            1: 'Payment.TypePayment',
            2: 'Payment.TypeDeposite'
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.profile) {
            this.loadDiners();
            this.loadHistories();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profile && !this.props.profile) {
            this.loadDiners();
            this.loadHistories();
        }
    }

    loadHistories() {
        this.apiService.getMyHistory(this.state.period.date)
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    this.setState({ history: { data } });
                }
            })
            .catch(error => {
                console.log('Payment.loadHistories error', error);
            })
    }

    loadDiners() {
        this.setState({ diners: { ...this.state.diners, pending: true } });
        this.apiService.getAccountDiners()
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    let self = _.find(response.data, { isUser: true });
                    let selected = self ? self.id : (Object.keys(data).length ? Object.keys(data)[0] : '');
                    this.setState({
                        diners: {
                            ...this.state.diners,
                            pending: false,
                            data,
                            self,
                            selected
                        }
                    }, () => {
                        // console.log('LunchMenu.loadDiners diners loaded', this.state.diners);
                        // this.loadOrders();
                    });
                }
            })
            .catch(error => {
                console.log('Payment.loadDiners error', error);
                if (this.mounted) {
                    this.setState({
                        menu: {
                            ...this.state.diners,
                            pending: false,
                            error: error
                        }
                    });
                }
            })
    }

    onPeriodChange(period) {
        this.setState({ period: { date: period } }, () => {
            this.loadHistories();
        });
    }

    selectedDinerChanged(id) {
        let { diners } = this.state;
        this.setState({ diners: { ...diners, selected: id } }, () => {
            this.loadHistories();
        });
    }

    render() {
        let { diners, history, period } = this.state;

        let dinerCount = Object.keys(diners.data).length;
        let isSomeDiner = dinerCount > 0;
        let isUserDiner = !!diners.self;
        let showDinerSelector = isUserDiner ? dinerCount > 1 : isSomeDiner;
        let dinerItems = [];
        if (isSomeDiner) {
            dinerItems = arraySort(_.map(diners.data), 'name');
        }
        let items = arraySort(_.map(history.data), { prop: 'time', order: 'asc' }).filter(o => o.dinerId === diners.selected);
        let diner = diners.data[diners.selected] || {};
        let dinerName = diner.name || '';
        let balance = (diner.balance === null || diner.balance === undefined) ? '' : diner.balance;

        let sumaPayments = -items.reduce((r, o) => o.type === PT_PAYMENT ? r + o.amount : r, 0);
        let sumaDeposites = items.reduce((r, o) => o.type === PT_DEPOSITE ? r + o.amount : r, 0);

        return (
            <div className="page-container payment-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Period')}</label>
                            <Period
                                period={period.date}
                                onChange={p => this.onPeriodChange(p)}
                            />
                        </div>
                        {showDinerSelector &&
                            <div className="filter-group">
                                <label>{this.context.translate('Filter.Diner')}</label>
                                <select
                                    className="form-control diner-selector"
                                    name="diner"
                                    value={diners.selected}
                                    onChange={e => this.selectedDinerChanged(e.target.value)}
                                >
                                    {dinerItems.map(o => (
                                        <option key={o.id || 0} value={o.id}>{o.name}</option>
                                    ))}
                                </select>
                            </div>
                        }
                    </div>
                    <div className="delimiter"></div>
                    <div className="info">
                        <div className="item balance">
                            <div className="label">{this.context.translate('Payment.Balance')}</div>
                            <div className="value">{balance} {this.context.translate('Units.Money')}</div>
                        </div>
                        <div className="item">
                            <div className="label">{this.context.translate('Payment.TotalPayment')}</div>
                            <div className="value">{sumaPayments} {this.context.translate('Units.Money')}</div>
                        </div>
                        <div className="item">
                            <div className="label">{this.context.translate('Payment.TotalDeposite')}</div>
                            <div className="value">{sumaDeposites} {this.context.translate('Units.Money')}</div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table history-table">
                        <colgroup>
                            <col className="col-time" />
                            <col className="col-diner" />
                            <col className="col-type" />
                            <col className="col-description" />
                            <col className="col-amount" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="ta-c">{this.context.translate('Payment.Time')}</th>
                                <th className="ta-c">{this.context.translate('Payment.Diner')}</th>
                                <th className="ta-c">{this.context.translate('Payment.Type')}</th>
                                <th className="ta-c">{this.context.translate('Payment.Description')}</th>
                                <th className="ta-c">{this.context.translate('Payment.Amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => {
                                let description = o.description;
                                if ((o.type === PT_PAYMENT) && o.mealType) {
                                    description = `${this.context.translate('MealType.' + o.mealType)}`;
                                }
                                let coef = o.type === PT_PAYMENT ? -1 : 1;
                                return (
                                    <tr
                                        key={o.id}
                                        className={classnames({ payment: o.type === PT_PAYMENT, deposite: o.type === PT_DEPOSITE })}
                                    >
                                        <td className="ta-c">{toStandardDmySpan(o.time)}</td>
                                        <td>{dinerName}</td>
                                        <td>{this.context.translate(this.types[o.type])}</td>
                                        <td>{description}</td>
                                        <td className="ta-r">{o.amount * coef} {this.context.translate('Units.Money')}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        profile: state.profile.loaded
    }),
    {}
)(Payment);
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Route, Switch, Redirect } from 'react-router'

import Order from './order/Order';
import { isViewer } from '../../utils/role';
import Daily from './daily/Daily';
import Monthly from './monthly/Monthly';
import MonthlyPrint from './monthly/MonthlyPrint';


class ViewContent extends React.Component {
    static propTypes = {
        profile: PropTypes.object,
        path: PropTypes.string,
        push: PropTypes.func.isRequired
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    componentWillMount() {
        if (!isViewer(this.props.profile) && !this.props.profile.pending) {
            console.log('ViewContent.componentWillMount: redirect because user is not viewer', this.props.profile);
            this.props.push('/');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.profile.pending && this.props.profile.pending) {
            if (!isViewer(nextProps.profile)) {
                console.log('ViewContent.componentWillReceiveProps: redirect because user is not viewer', this.props.profile);
                this.props.push('/');
            }
        }
    }

    render() {
        let show = isViewer(this.props.profile);

        return (
            <>
                {show &&
                    <div className="imsp-view-section">
                        <Switch>
                            <Route path="/view/daily" component={Daily} />
                            <Route path="/view/monthly/print" component={MonthlyPrint} />
                            <Route path="/view/monthly" component={Monthly} />
                            <Route path="/view/order" component={Order} />
                            <Redirect from="/view" to="/view/order" />
                        </Switch>
                    </div>
                }
            </>
        );
    }
};

export default connect(
    state => ({
        profile: state.profile,
        path: state.router.location.pathname
    }),
    {
        push
    }
)(ViewContent);
import axios from 'axios';
import { Constants } from '../helpers/Constants';
import { AuthService } from './AuthService';
import { toModalDate } from '../utils/date';
import { isImspError, getImspErrorCodes } from '../utils/error';

export class ApiService {

    constructor() {
        this.authService = new AuthService();
    }

    //#region /diner

    setDinerStudent(id, studentId) {
        return this.callAuthorized('post', `diner/${id}/student`, { studentId });
    }

    //#endregion

    //#region /student

    getStudents(withoutDiner) {
        let params = {
            withoutDiner
        };
        return this.callAuthorized('get', 'student', null, { params });
    }
    
    //#endregion

    getMenu(date) {
        let params = {
            period: toModalDate(date)
        };
        return this.getUnauthorized("menu", { params });
    }

    getProfile() {
        return this.callAuthorized('get', 'user');
    }

    getAccounts() {
        return this.callAuthorized('get', 'account');
    }

    getAccountsWithDiners() {
        return this.callAuthorized('get', 'account/withDiners');
    }

    getAccountDiners() {
        return this.callAuthorized('get', 'account/diner');
    }

    getDiners(params = {}) {
        return this.callAuthorized('get', 'diner', null, { params });
    }

    getGroups() {
        return this.callAuthorized('get', 'group');
    }

    getOrders(date, dinerId) {
        let params = {
            period: toModalDate(date),
            dinerId
        };
        return this.callAuthorized('get', 'order', null, { params });
    }

    getAdminOrders(from, to, dinerId) {
        let params = {
            from: toModalDate(from),
            to: toModalDate(to),
            dinerId
        };
        return this.callAuthorized('get', 'order/admin', null, { params });
    }

    getDailyOrders(date) {
        let params = {
            day: toModalDate(date)
        };
        return this.callAuthorized('get', 'order/day', null, { params });
    }

    getMonthlyOrders(date) {
        let params = {
            day: toModalDate(date)
        };
        return this.callAuthorized('get', 'order/month', null, { params });
    }

    getPrices() {
        return this.callAuthorized('get', 'settings/price');
    }

    getSettings() {
        return this.callAuthorized('get', 'settings');
    }

    getMyHistory(period) {
        let params = {
            period: toModalDate(period)
        };
        return this.callAuthorized("get", "history/my", null, { params });
    }

    getHistory(period) {
        let params = {
            period: toModalDate(period)
        };
        return this.callAuthorized("get", "history", null, { params });
    }

    getAlergens() {
        return this.callAuthorized('get', 'alergen');
    }

    getBillings(period) {
        let params = {
            period: toModalDate(period)
        };
        return this.callAuthorized('get', 'billing', null, { params });
    }

    getBalanceNotices() {
        return this.callAuthorized('get', 'view/warning');
    }

    createProfile(id, name, email) {
        return this.callAuthorized('post', 'user', { id, name, email });
    }

    addDiner(data) {
        return this.callAuthorized('post', 'diner', data);
    }

    addGroup(name, monthOrdersOrder) {
        return this.callAuthorized('post', 'group', { name, monthOrdersOrder });
    }

    addHistory(time, diner, type, description, amount, mealType, note) {
        return this.callAuthorized('post', 'history', { time, dinerId: diner, type, description, amount, mealType, note });
    }

    setMenu(date, lang, soup, soupAlg, menu1, menu1Alg, menu2, menu2Alg, menu3, menu3Alg, snack, snackAlg) {
        return this.callAuthorized('post', 'menu', { date, lang, soup, soupAlg, menu1, menu1Alg, menu2, menu2Alg, menu3, menu3Alg, snack, snackAlg });
    }

    setOrder(date, dinerId, menu, snack) {
        return this.callAuthorized('post', 'order', { date, dinerId, menu, snack });
    }

    setOrders(dinerId, orders) {
        return this.callAuthorized('post', 'order/many', { dinerId, orders });
    }

    setAlergen(id, cs, en) {
        return this.callAuthorized('post', 'alergen', { id, cs, en });
    }

    setSettings(id, menu1Price, menu2Price, menu3Price, snackPrice, accountNumber, specificSymbol, debitLimit, sendOrdersEnabled, sendOrdersTo, sendOrdersCc, sendBalanceWarning, balanceWarningDay, sendNoticeToOrder, sendNoticeToOrderDayBefore) {
        return this.callAuthorized('post', 'settings', { id, menu1Price, menu2Price, menu3Price, snackPrice, accountNumber, specificSymbol, debitLimit, sendOrdersEnabled, sendOrdersTo, sendOrdersCc, sendBalanceWarning, balanceWarningDay, sendNoticeToOrder, sendNoticeToOrderDayBefore });
    }

    updateDiner(id, data) {
        return this.callAuthorized('put', `diner/${id}`, data);
    }

    updateGroup(id, name, monthOrdersOrder) {
        return this.callAuthorized('put', `group/${id}`, { name, monthOrdersOrder });
    }

    updateAccount(id, roles) {
        return this.callAuthorized('put', `account/${id}`, { roles });
    }

    updateProfile(name) {
        return this.callAuthorized('put', `user`, { name });
    }

    updateBilling(name, address, accountNumber, ico) {
        return this.callAuthorized('put', `user/billing`, { name, address, accountNumber, ico });
    }

    updateHistory(id, time, diner, type, description, amount, mealType, note) {
        return this.callAuthorized('put', `history/${id}`, { time, dinerId: diner, type, description, amount, mealType, note });
    }

    moveDiners(diners, group) {
        return this.callAuthorized('post', 'diner/move', { diners, group });
    }

    deleteAccount(id, force = false) {
        return this.callAuthorized('delete', `account/${id}`, { force });
    }

    deleteGroup(id, force = false) {
        return this.callAuthorized('delete', `group/${id}`, { force });
    }

    deleteAlergen(id) {
        return this.callAuthorized('delete', `alergen/${id}`);
    }

    deletePayment(id) {
        return this.callAuthorized('delete', `history/${id}`);
    }

    deleteDiner(id, force = false) {
        return this.callAuthorized('delete', `diner/${id}`, { force });
    }

    deleteOrders(keys) {
        return this.callAuthorized('post', 'order/delete', { keys });
    }

    removeMenu(date, lang) {
        return this.callAuthorized('post', 'menu/remove', { date, lang });
    }

    importMenu(data) {
        return this.callAuthorized('post', `menu/import`, data, { headers: { 'content-type': 'multipart/form-data' } });
    }

    importTransactions(data) {
        return this.callAuthorized('post', `history/import`, data, { headers: { 'content-type': 'multipart/form-data' } });
    }

    exportBillings(period) {
        let params = {
            period: toModalDate(period)
        };
        return this.callAuthorized('get', 'billing/export', null, { params, responseType: 'blob' });
    }

    exportOrdersSummary(period, language, selected) {
        let params = {
            period: toModalDate(period),
            language,
            selected
        };
        return this.callAuthorized('get', 'order/export/summary', null, { params, responseType: 'blob' });
    }

    exportMenuPdf(period, lng) {
        let params = {
            period: toModalDate(period),
            lng
        };
        return this.callAuthorized('get', 'menu/export/pdf', null, { params, responseType: 'blob' });
    }

    exportDateOrders(date, group, lang) {
        let params = {
            date: toModalDate(date),
            group,
            lang
        };
        return this.callAuthorized('get', 'order/export/daily', null, { params, responseType: 'blob' });
    }

    exportMonthOrders(date, group, lang) {
        let params = {
            date: toModalDate(date),
            group,
            lang
        };
        return this.callAuthorized('get', 'order/export/monthly', null, { params, responseType: 'blob' });
    }

    sendEmail(to, subject, message) {
        return this.callAuthorized('post', 'email', { to, subject, message });
    }

    recalculateBillings(period) {
        let data = {
            period: toModalDate(period)
        };
        return this.callAuthorized('post', 'billing/run', data);
    }

    getUnauthorized(path, options) {
        return axios.get(Constants.apiRoot + path, options);
    }

    callAuthorized(method, path, data, options) {
        return this.authService.getUser().then(user => {
            if (user && user.access_token) {
                return this._callApi(user.access_token, method, path, data, options).catch(error => {
                    console.log(method, path, error);
                    if (isImspError(error)) {
                        console.log(getImspErrorCodes(error));
                    }
                    if (error.response && error.response.status === 401) {
                        return this.authService.renewToken().then(renewedUser => {
                            return this._callApi(renewedUser.access_token, method, path, data, options);
                        });
                    }
                    throw error;
                });
            } else if (user) {
                return this.authService.renewToken().then(renewedUser => {
                    return this._callApi(renewedUser.access_token, method, path, data, options);
                });
            } else {
                throw new Error('user is not logged in');
            }
        });
    }

    _callApi(token, method, path, data, options) {
        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        };
        options = { ...(options || {}) };
        options.headers = { ...(options.headers || {}), ...headers };
        switch (method) {
            case 'put':
                return axios.put(Constants.apiRoot + path, data, options);
            case 'post':
                return axios.post(Constants.apiRoot + path, data, options);
            case 'delete':
                if (data) {
                    return axios.delete(Constants.apiRoot + path, { ...options, data });
                } else {
                    return axios.delete(Constants.apiRoot + path, options);
                }
            default:
                return axios.get(Constants.apiRoot + path, options);
        }
    }
}

import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ApiService } from '../../../services/ApiService';
import { initPeriod, nextPeriod } from '../../../utils/period';
import { nextDay, toModalDate, toLunchDate, toPeriodDate, parseDate } from '../../../utils/date';
import Period from '../../../components/Period';

import './order.scss';
import { getLangId } from '../../../utils/language';


class Order extends React.Component {
    static propTypes = {
        language: PropTypes.string.isRequired
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            menu: {},
            orders: {},
            period: initPeriod(),
            selected: ''
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadMenu();
        this.loadOrders();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadMenu() {
        let { period } = this.state;

        this.api.getMenu(period)
            .then(response => {
                if (this.mounted) {
                    let menu = this.transformData(response.data);
                    this.setState({ menu });
                }
            })
            .catch(error => {
                console.log('Order.loadMenu error', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            })
    }

    loadOrders() {
        this.api.getOrders(this.state.period)
            .then(response => {
                if (this.mounted) {
                    let orders = response.data.reduce((r, o) => {
                        let d = toModalDate(o.date);
                        if (!(d in r)) {
                            r[d] = {
                                date: d,
                                menu1: 0,
                                menu2: 0,
                                menu3: 0,
                                snack: 0
                            };
                        }
                        if (o.menu === 1) {
                            r[d].menu1++;
                        }
                        if (o.menu === 2) {
                            r[d].menu2++;
                        }
                        if (o.menu === 3) {
                            r[d].menu3++;
                        }
                        if (o.snack) {
                            r[d].snack++;
                        }
                        return r;
                    }, {});
                    console.log('Order.loadOrders', { data: response.data, orders });
                    this.setState({ orders });
                }
            })
            .catch(error => {
                console.log('Order.loadOrders error', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            })
    }

    transformData(data) {
        let result = (data || []).reduce((r, o) => {
            let time = parseDate(o.date);
            r[time] = r[time] || {};
            r[time][o.lang] = o;
            return r;
        }, {});
        return result;
    }

    onPeriodChange(period) {
        this.setState({ period }, () => {
            this.loadMenu();
            this.loadOrders();
        });
    }

    buildItems() {
        let { period, menu, orders } = this.state;

        let result = [];
        let nextPeriodTime = nextPeriod(period).getTime();
        let lngId = getLangId(this.props.language);
        for (let d = new Date(period.getTime()); d.getTime() < nextPeriodTime; d = nextDay(d)) {
            let item = { date: toModalDate(d) };
            
            let time = d.getTime();
            let m = menu[time] || {};
            if (Object.keys(m).length) {
                if (lngId in m) {
                    item = { ...m[lngId], date: item.date };
                } else {
                    item = { ...m[Object.keys(m)[0]], date: item.date };
                }
            }

            if (item.date in orders) {
                item.menu1count = orders[item.date].menu1;
                item.menu2count = orders[item.date].menu2;
                item.menu3count = orders[item.date].menu3;
                item.snackcount = orders[item.date].snack;
            } else {
                item.menu1count = 0;
                item.menu2count = 0;
                item.menu3count = 0;
                item.snackcount = 0;
            }

            result.push(item);
        }
        return result;
    }

    export() {
        let { period, selected } = this.state;
        this.api.exportOrdersSummary(period, this.props.language, selected)
            .then(response => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `orders-${selected ? toModalDate(selected) : toPeriodDate(period)}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.log('Order.export error', error);
            })
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? null : key });
    }

    render() {
        let { period, selected } = this.state;

        let items = this.buildItems();

        return (
            <div className="page-container order-view-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Period')}</label>
                            <Period
                                period={period}
                                onChange={p => this.onPeriodChange(p)}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.export()}
                        >{this.context.translate('Btn.Export')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-date" />
                            <col className="col-soup" />
                            <col className="col-menu1" />
                            <col className="col-count" />
                            <col className="col-menu2" />
                            <col className="col-count" />
                            <col className="col-menu3" />
                            <col className="col-count" />
                            <col className="col-snack" />
                            <col className="col-count" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{this.context.translate('OrderView.Date')}</th>
                                <th>{this.context.translate('MealType.0')}</th>
                                <th>{this.context.translate('MealType.1')}</th>
                                <th>{this.context.translate('OrderView.Count')}</th>
                                <th>{this.context.translate('MealType.2')}</th>
                                <th>{this.context.translate('OrderView.Count')}</th>
                                <th>{this.context.translate('MealType.3')}</th>
                                <th>{this.context.translate('OrderView.Count')}</th>
                                <th>{this.context.translate('MealType.4')}</th>
                                <th>{this.context.translate('OrderView.Count')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => {
                                let key = o.date;
                                return (
                                    <tr
                                        key={key}
                                        className={classnames({ selected: selected === key })}
                                        onClick={() => this.select(key)}
                                    >
                                        <td className="ta-r">{toLunchDate(o.date)}</td>
                                        <td>{o.soup}</td>
                                        <td>{o.menu1}</td>
                                        <td>{o.menu1count}</td>
                                        <td>{o.menu2}</td>
                                        <td>{o.menu2count}</td>
                                        <td>{o.menu3}</td>
                                        <td>{o.menu3count}</td>
                                        <td>{o.snack}</td>
                                        <td>{o.snackcount}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        language: state.localize.language
    }),
    {}
)(Order);
import * as toastr from 'toastr';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ApiService } from '../../../services/ApiService';

import './settings.scss';


class Settings extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            edit: {
                state: '',
                menu1Price: '',
                menu2Price: '',
                menu3Price: '',
                snackPrice: '',
                accountNumber: '',
                debitLimit: '',
                specificSymbol: '',
                sendOrdersEnabled: false,
                sendOrdersTo: '',
                sendOrdersCc: '',
                sendBalanceWarning: false,
                balanceWarningDay: '',
                sendNoticeToOrder: false,
                sendNoticeToOrderDayBefore: ''
            }
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadData();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadData() {
        this.api.getSettings()
            .then(response => {
                if (this.mounted) {
                    this.setState({ data: response.data });
                }
            })
            .catch(error => {
                console.log('Settings.loadData error', error);
            })
    }

    saveMealPrice() {
        if (this.validateMealPrice()) {
            let { id, accountNumber, specificSymbol, debitLimit,
                sendOrdersEnabled, sendOrdersTo, sendOrdersCc,
                sendBalanceWarning, balanceWarningDay,
                sendNoticeToOrder, sendNoticeToOrderDayBefore } = this.state.data;
            let { menu1Price, menu2Price, menu3Price, snackPrice } = this.state.edit;

            this.api.setSettings(id, menu1Price, menu2Price, menu3Price, snackPrice, accountNumber, specificSymbol, debitLimit, sendOrdersEnabled, sendOrdersTo, sendOrdersCc, sendBalanceWarning, balanceWarningDay, sendNoticeToOrder, sendNoticeToOrderDayBefore)
                .then(response => {
                    this.loadData();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Settings.MealPriceSuccess'));
                })
                .catch(error => {
                    console.log('Settings.saveMealPrice error save meal price', error);
                    toastr.error(error);
                });
        }
    }

    saveOther() {
        if (this.validateOther()) {
            let { id, menu1Price, menu2Price, menu3Price, snackPrice,
                sendOrdersEnabled, sendOrdersTo, sendOrdersCc,
                sendBalanceWarning, balanceWarningDay,
                sendNoticeToOrder, sendNoticeToOrderDayBefore } = this.state.data;
            let { accountNumber, specificSymbol, debitLimit } = this.state.edit;

            this.api.setSettings(id, menu1Price, menu2Price, menu3Price, snackPrice, accountNumber, specificSymbol, debitLimit, sendOrdersEnabled, sendOrdersTo, sendOrdersCc, sendBalanceWarning, balanceWarningDay, sendNoticeToOrder, sendNoticeToOrderDayBefore)
                .then(response => {
                    this.loadData();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Settings.OtherSuccess'));
                })
                .catch(error => {
                    console.log('Settings.saveOther error save other settings', error);
                    toastr.error(error);
                });
        }
    }

    saveSendOrders() {
        if (this.validateSendOrders()) {
            let { id, menu1Price, menu2Price, menu3Price, snackPrice,
                accountNumber, specificSymbol, debitLimit,
                sendBalanceWarning, balanceWarningDay,
                sendNoticeToOrder, sendNoticeToOrderDayBefore } = this.state.data;
            let { sendOrdersEnabled, sendOrdersTo, sendOrdersCc } = this.state.edit;

            this.api.setSettings(id, menu1Price, menu2Price, menu3Price, snackPrice, accountNumber, specificSymbol, debitLimit, sendOrdersEnabled, sendOrdersTo, sendOrdersCc, sendBalanceWarning, balanceWarningDay, sendNoticeToOrder, sendNoticeToOrderDayBefore)
                .then(response => {
                    this.loadData();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Settings.SendOrdersSuccess'));
                })
                .catch(error => {
                    console.log('Settings.saveSendOrders error save other settings', error);
                    toastr.error(error);
                });
        }
    }

    saveBalanceWarning() {
        if (this.validateSendBalanceWarning()) {
            let { id, menu1Price, menu2Price, menu3Price, snackPrice,
                accountNumber, specificSymbol, debitLimit,
                sendOrdersEnabled, sendOrdersTo, sendOrdersCc,
                sendNoticeToOrder, sendNoticeToOrderDayBefore } = this.state.data;
            let { sendBalanceWarning, balanceWarningDay } = this.state.edit;

            this.api.setSettings(id, menu1Price, menu2Price, menu3Price, snackPrice, accountNumber, specificSymbol, debitLimit, sendOrdersEnabled, sendOrdersTo, sendOrdersCc, sendBalanceWarning, balanceWarningDay, sendNoticeToOrder, sendNoticeToOrderDayBefore)
                .then(response => {
                    this.loadData();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Settings.SendBalanceWarningSuccess'));
                })
                .catch(error => {
                    console.log('Settings.saveBalanceWarning error save balance warning settings', error);
                    toastr.error(error);
                });
        }
    }

    saveNoticeToOrder() {
        if (this.validateSendNoticeToOrder()) {
            let { id, menu1Price, menu2Price, menu3Price, snackPrice,
                accountNumber, specificSymbol, debitLimit,
                sendOrdersEnabled, sendOrdersTo, sendOrdersCc,
                sendBalanceWarning, balanceWarningDay } = this.state.data;
            let { sendNoticeToOrder, sendNoticeToOrderDayBefore } = this.state.edit;

            this.api.setSettings(id, menu1Price, menu2Price, menu3Price, snackPrice, accountNumber, specificSymbol, debitLimit, sendOrdersEnabled, sendOrdersTo, sendOrdersCc, sendBalanceWarning, balanceWarningDay, sendNoticeToOrder, sendNoticeToOrderDayBefore)
                .then(response => {
                    this.loadData();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Settings.SendNoticeToOrderSuccess'));
                })
                .catch(error => {
                    console.log('Settings.saveNoticeToOrder error save notice to order settings', error);
                    toastr.error(error);
                });
        }
    }

    validateSendNoticeToOrder() {
        return true;
    }

    validateSendBalanceWarning() {
        return true;
    }

    validateSendOrders() {
        return true;
    }

    validateOther() {
        return true;
    }

    validateMealPrice() {
        return true;
    }

    render() {
        let { data, edit } = this.state;
        let { menu1Price, menu2Price, menu3Price, snackPrice,
            accountNumber, debitLimit, specificSymbol,
            sendOrdersEnabled, sendOrdersTo, sendOrdersCc,
            sendBalanceWarning, balanceWarningDay,
            sendNoticeToOrder, sendNoticeToOrderDayBefore } = data;

        let mealPriceEdit = edit.state === 'mealPrice';
        let otherEdit = edit.state === 'other';
        let soEdit = edit.state === 'so';
        let sbwEdit = edit.state === 'sbw';
        let sntoEdit = edit.state === 'snto';

        return (
            <div className="settings-page">
                <div className="settings-page-content">
                    <div className="section">
                        <div className="section-title">{this.context.translate('Settings.MealPrice')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('MealType.1')}</div>
                                {!mealPriceEdit &&
                                    <div className="value ta-r">{menu1Price} {this.context.translate('Units.Money')}</div>
                                }
                                {mealPriceEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        autoFocus
                                        value={edit.menu1Price || ''}
                                        onChange={e => this.setState({ edit: { ...edit, menu1Price: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('MealType.2')}</div>
                                {!mealPriceEdit &&
                                    <div className="value ta-r">{menu2Price} {this.context.translate('Units.Money')}</div>
                                }
                                {mealPriceEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.menu2Price || ''}
                                        onChange={e => this.setState({ edit: { ...edit, menu2Price: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('MealType.3')}</div>
                                {!mealPriceEdit &&
                                    <div className="value ta-r">{menu3Price} {this.context.translate('Units.Money')}</div>
                                }
                                {mealPriceEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.menu3Price || ''}
                                        onChange={e => this.setState({ edit: { ...edit, menu3Price: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('MealType.4')}</div>
                                {!mealPriceEdit &&
                                    <div className="value ta-r">{snackPrice} {this.context.translate('Units.Money')}</div>
                                }
                                {mealPriceEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.snackPrice || ''}
                                        onChange={e => this.setState({ edit: { ...edit, snackPrice: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="buttons">
                                {!mealPriceEdit &&
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: 'mealPrice', menu1Price, menu2Price, menu3Price, snackPrice } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                                {mealPriceEdit &&
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveMealPrice()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-title">{this.context.translate('Settings.SendingOrders')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.SoEnabled')}</div>
                                {!soEdit &&
                                    <div className="value ta-r">{sendOrdersEnabled ? this.context.translate('Codelist.Yes') : this.context.translate('Codelist.No')}</div>
                                }
                                {soEdit &&
                                    <input
                                        className="checkbox-control"
                                        type="checkbox"
                                        checked={edit.sendOrdersEnabled}
                                        onChange={e => this.setState({ edit: { ...edit, sendOrdersEnabled: e.target.checked } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.SoTo')}</div>
                                {!soEdit &&
                                    <div className="value ta-r">{sendOrdersTo}</div>
                                }
                                {soEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        autoFocus
                                        value={edit.sendOrdersTo || ''}
                                        onChange={e => this.setState({ edit: { ...edit, sendOrdersTo: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.SoCc')}</div>
                                {!soEdit &&
                                    <div className="value ta-r">{sendOrdersCc}</div>
                                }
                                {soEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.sendOrdersCc || ''}
                                        onChange={e => this.setState({ edit: { ...edit, sendOrdersCc: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="buttons">
                                {!soEdit &&
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: 'so', sendOrdersEnabled, sendOrdersTo, sendOrdersCc } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                                {soEdit &&
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveSendOrders()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-title">{this.context.translate('Settings.SendBalanceWarning')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.SbwEnabled')}</div>
                                {!sbwEdit &&
                                    <div className="value ta-r">{sendBalanceWarning ? this.context.translate('Codelist.Yes') : this.context.translate('Codelist.No')}</div>
                                }
                                {sbwEdit &&
                                    <input
                                        className="checkbox-control"
                                        type="checkbox"
                                        checked={edit.sendBalanceWarning}
                                        onChange={e => this.setState({ edit: { ...edit, sendBalanceWarning: e.target.checked } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.BalanceWarningDay')}</div>
                                {!sbwEdit &&
                                    <div className="value ta-r">{balanceWarningDay}</div>
                                }
                                {sbwEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.balanceWarningDay || ''}
                                        onChange={e => this.setState({ edit: { ...edit, balanceWarningDay: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="buttons">
                                {!sbwEdit &&
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: 'sbw', sendBalanceWarning, balanceWarningDay } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                                {sbwEdit &&
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveBalanceWarning()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-title">{this.context.translate('Settings.SendNoticeToOrder')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.SbwEnabled')}</div>
                                {!sntoEdit &&
                                    <div className="value ta-r">{sendNoticeToOrder ? this.context.translate('Codelist.Yes') : this.context.translate('Codelist.No')}</div>
                                }
                                {sntoEdit &&
                                    <input
                                        className="checkbox-control"
                                        type="checkbox"
                                        checked={edit.sendNoticeToOrder}
                                        onChange={e => this.setState({ edit: { ...edit, sendNoticeToOrder: e.target.checked } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.SendNoticeToOrderDayBefore')}</div>
                                {!sntoEdit &&
                                    <div className="value ta-r">{sendNoticeToOrderDayBefore}</div>
                                }
                                {sntoEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.sendNoticeToOrderDayBefore || ''}
                                        onChange={e => this.setState({ edit: { ...edit, sendNoticeToOrderDayBefore: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="buttons">
                                {!sntoEdit &&
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: 'snto', sendNoticeToOrder, sendNoticeToOrderDayBefore } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                                {sntoEdit &&
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveNoticeToOrder()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-title">{this.context.translate('Settings.Other')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.AccountNumber')}</div>
                                {!otherEdit &&
                                    <div className="value ta-r">{accountNumber}</div>
                                }
                                {otherEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        autoFocus
                                        value={edit.accountNumber || ''}
                                        onChange={e => this.setState({ edit: { ...edit, accountNumber: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.SpecificSymbol')}</div>
                                {!otherEdit &&
                                    <div className="value ta-r">{specificSymbol}</div>
                                }
                                {otherEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.specificSymbol || ''}
                                        onChange={e => this.setState({ edit: { ...edit, specificSymbol: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.DebitLimit')}</div>
                                {!otherEdit &&
                                    <div className="value ta-r">{debitLimit} {this.context.translate('Units.Money')}</div>
                                }
                                {otherEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.debitLimit || ''}
                                        onChange={e => this.setState({ edit: { ...edit, debitLimit: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="buttons">
                                {!otherEdit &&
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: 'other', accountNumber, debitLimit, specificSymbol } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                                {otherEdit &&
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveOther()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Settings);
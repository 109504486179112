import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Select from 'react-select';

import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import DateSelector from '../../../components/dateSelector/DateSelector';
import { today, toModalDate } from '../../../utils/date';

import './daily.scss';
import schoolYear from '../../../utils/schoolYear';


class Daily extends React.Component {
    static propTypes = {
        language: PropTypes.string.isRequired
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            filter: {
                date: today()
            },
            groups: {},
            orders: {},
            diners: {},
            groupFilter: []
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadDiners();
        this.loadGroups();
        this.loadOrders();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadDiners() {
        let { filter } = this.state;

        this.api.getDiners({ year: schoolYear(filter.date).year() })
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    this.setState({ diners: data });
                }
            })
            .catch(error => {
                console.log('Daily.loadDiners', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            });
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                if (this.mounted) {
                    let groups = _.mapKeys(response.data, 'id');
                    this.setState({ groups });
                }
            })
            .catch(error => {
                console.log('Daily.loadGroups', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            });
    }

    loadOrders() {
        this.api.getDailyOrders(this.state.filter.date)
            .then(response => {
                if (this.mounted) {
                    let orders = _.mapKeys(response.data, 'dinerId');
                    this.setState({ orders });
                }
            })
            .catch(error => {
                console.log('Daily.loadOrders error', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            })
    }

    onPeriodChange(date) {
        this.setState({ filter: { ...this.state.filter, date } }, () => {
            this.loadOrders();
            this.loadDiners();
        });
    }

    export() {
        let { date, group } = this.state.filter;
        let { language } = this.props;

        this.api.exportDateOrders(date, group, language)
            .then(response => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `orders-daily-${toModalDate(date)}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.log('Daily.export error', error);
            })
    }

    renderTrueFalse(value) {
        return (value ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times" aria-hidden="true"></i>);
    }

    getGroupFilterOptions(groups) {
        let result = _.map(groups || {}, o => ({ value: o.id, label: o.name }));
        arraySort(result, 'label');
        result.push({ value: null, label: this.context.translate('Filter.NoGroup') });
        return result;
    }

    render() {
        let { translate } = this.context;
        let { filter, groups, orders, diners, groupFilter } = this.state;
        let { date } = filter;
        
        let groupFilterOptions = this.getGroupFilterOptions(groups);
        
        let items = { ...diners };
        Object.keys(items).forEach(o => {
            items[o] = {
                ...items[o],
                groupName: (groups[items[o].groupId] || {}).name,
                groupOrder: (groups[items[o].groupId] || {}).monthOrdersOrder || 0
            };
        })
        _.map(orders).forEach(o => items[o.dinerId] = { ...items[o.dinerId], ...o })
        items = arraySort(_.map(items), 'groupOrder', 'groupName', 'name');
        items = items.filter(o => !o.hidden);
        if (groupFilter && groupFilter.length) {
            items = items.filter(o => _.some(groupFilter, { value: o.groupId }));
        }

        return (
            <div className="page-container diner-order-view-page">
                <div className="page-menu simple-page">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Date')}</label>
                            <DateSelector
                                date={date}
                                onChange={p => this.onPeriodChange(p)}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Group')}</label>
                            <Select
                                options={groupFilterOptions}
                                isMulti
                                onChange={groupFilter => this.setState({ groupFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.export()}
                        >{this.context.translate('Btn.Export')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-diner" />
                            <col className="col-group" />
                            <col className="col-menu1" />
                            <col className="col-menu2" />
                            <col className="col-menu3" />
                            <col className="col-snack" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="col">{this.context.translate('Daily.Diner')}</th>
                                <th className="col">{this.context.translate('Daily.Group')}</th>
                                <th className="col ta-c">{this.context.translate('MealType.1')}</th>
                                <th className="col ta-c">{this.context.translate('MealType.2')}</th>
                                <th className="col ta-c">{this.context.translate('MealType.3')}</th>
                                <th className="col ta-c">{this.context.translate('MealType.4')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => (
                                <tr
                                    key={o.id}
                                    // className={classnames({ selected: this.state.selected === o.id })}
                                    // onClick={() => this.select(o.id)}
                                >
                                    <td>{o.name}</td>
                                    <td>{(groups[o.groupId] || {}).name}</td>
                                    <td className="ta-c">{this.renderTrueFalse(o.menu === 1)}</td>
                                    <td className="ta-c">{this.renderTrueFalse(o.menu === 2)}</td>
                                    <td className="ta-c">{this.renderTrueFalse(o.menu === 3)}</td>
                                    <td className="ta-c">{this.renderTrueFalse(o.snack)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
        language: state.localize.language
    }),
    {}
)(Daily);
import _ from 'lodash';
import * as React from 'react';
import * as toastr from 'toastr';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';

import './group.scss';
import { errorEquals, ERR_DEPENDENT_DATA } from '../../../utils/error';


class Group extends React.Component {
    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            groups: {},
            modal: {
                open: false,
                id: '',
                name: ''
            },
            selected: ''
        };
        this.api = new ApiService();
        this.mounted = false;
    }

    componentWillMount() {
        this.loadGroups();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                let groups = _.mapKeys(response.data, 'id');
                if (this.mounted) {
                    this.setState({ groups });
                }
            })
            .catch(error => {
                console.log('Diners.loadGroups', error);
            });
    }

    add() {
        this.setState({
            modal: {
                ...this.state.modal,
                open: true,
                id: '',
                name: '',
                monthOrdersOrder: 0
            }
        });
    }

    update(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        let { name, monthOrdersOrder } = this.state.groups[id] || {};
        this.setState({
            modal: {
                ...this.state.modal,
                open: true,
                id,
                name: name || '',
                monthOrdersOrder: monthOrdersOrder || 0
            }
        });
    }

    delete(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        if (!window.confirm(this.context.translate('Group.DeleteConfirm'))) {
            return;
        }

        let _self = this;
        this.api.deleteGroup(id, false)
            .then(response => {
                if (_self.mounted) {
                    if (response.data) {
                        _self.loadGroups();
                    }
                }
            })
            .catch(error => {
                if (this.mounted) {
                    if (errorEquals(error, ERR_DEPENDENT_DATA)) {
                        if (window.confirm(this.context.translate('Group.DeleteConfirmDependent'))) {
                            this.api.deleteGroup(id, true)
                                .then(response => {
                                    if (_self.mounted) {
                                        _self.loadGroups();
                                    }
                                })
                                .catch(error => {
                                    toastr.error(this.context.translate('Err.System'));
                                });
                        }
                    } else {
                        toastr.error(this.context.translate('Err.System'));
                    }
                }
                console.log('Group.delete', error);
            });;
    }

    closeModal() {
        this.setState({ modal: { ...this.state.modal, open: false } });
    }

    submitModal() {
        let { id, name, monthOrdersOrder } = this.state.modal;

        if (this.validateModal()) {
            if (id) {
                this.api.updateGroup(id, name, monthOrdersOrder)
                    .then(response => {
                        this.loadGroups();
                        this.closeModal();
                        toastr.success(this.context.translate('Group.EditSuccess'));
                    })
                    .catch(error => {
                        console.log('Group.submitModal error update group', error);
                        toastr.error(error);
                    });
            } else {
                this.api.addGroup(name, monthOrdersOrder)
                    .then(response => {
                        this.loadGroups();
                        this.closeModal();
                        toastr.success(this.context.translate('Group.AddSuccess'));
                    })
                    .catch(error => {
                        console.log('Group.submitModal error add group', error);
                        toastr.error(error);
                    });
            }
        }
    }

    validateModal() {
        return true;
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? null : key });
    }

    render() {
        let { groups, selected } = this.state;

        let items = arraySort(_.map(groups), 'monthOrdersOrder', 'name')
        let isSelected = !!selected;

        return (
            <div className="page-container group-page">
                <div className="page-menu">
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.add()}
                        >{this.context.translate('Btn.Add')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.update()}
                        >{this.context.translate('Btn.Update')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.delete()}
                        >{this.context.translate('Btn.Delete')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-name" />
                            <col className="col-order" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{this.context.translate('Group.Name')}</th>
                                <th>{this.context.translate('Group.Order')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => {
                                return (
                                    <tr
                                        key={o.id}
                                        className={classnames({ selected: selected === o.id })}
                                        onClick={() => this.select(o.id)}
                                    >
                                        <td>{o.name}</td>
                                        <td>{o.monthOrdersOrder}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <Modal
                    modalClass="group-modal"
                    onClose={() => this.closeModal()}
                    onSubmit={() => this.submitModal()}
                    title={this.context.translate('Group.Title')}
                    show={this.state.modal.open}
                    closeOnClick={true}
                >
                    <div>
                        <fieldset>
                            <input
                                type="hidden"
                                id="id"
                                name="id"
                            />
                            <div className="form-group">
                                <label htmlFor="name">{this.context.translate('Group.Name')}</label>
                                <input
                                    className="form-control"
                                    name="name"
                                    type="text"
                                    autoFocus
                                    value={this.state.modal.name}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, name: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="order">{this.context.translate('Group.Order')}</label>
                                <input
                                    className="form-control"
                                    name="order"
                                    type="text"
                                    value={this.state.modal.monthOrdersOrder}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, monthOrdersOrder: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.submitModal()}
                                >{this.context.translate('Btn.Save')}</button>
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeModal()}
                                >{this.context.translate('Btn.Cancel')}</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Group);
import * as React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ onClick }) => (
    <div
        className="imsp-logo"
        onClick={() => { if (onClick) onClick(); }}
    >
        <span className="logo"></span>
    </div>
);

Logo.propType = {
    onClick: PropTypes.func
}

export default Logo;
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Route, Switch, Redirect } from 'react-router'

import Account from './account/Account';
import Alergen from './alergen/Alergen';
import Billing from './billing/Billing';
import Diner from './diner/Diner';
import Emails from './emails/Emails';
import Group from '../../containers/admin/group/Group';
import Lunch from './lunch/Lunch';
import Notice from './notice/Notice';
import Payment from './payment/Payment';
import Settings from './settings/Settings';
import { isAdmin } from '../../utils/role';

import './adminContent.scss';
import Order from './order/Order';


class AdminContent extends React.Component {
    static propTypes = {
        profile: PropTypes.object,
        path: PropTypes.string,
        push: PropTypes.func.isRequired
    }

    componentWillMount() {
        if (!isAdmin(this.props.profile) && !this.props.profile.pending) {
            console.log('AdminContent.componentWillMount: redirect because user is not admin', this.props.profile);
            this.props.push('/');
        }
    }

    componentWillReceiveProps(nextProps) {
		if (!nextProps.profile.pending && this.props.profile.pending) {
            if (!isAdmin(nextProps.profile)) {
                console.log('AdminContent.componentWillReceiveProps: redirect because user is not admin', this.props.profile);
                this.props.push('/');
            }
        }
	}

    render() {
        let show = isAdmin(this.props.profile);

        return (
            <>
            { show &&
                <div className="imsp-admin-section">
                    <Switch>
                        <Route path="/admin/account" component={Account} />
                        <Route path="/admin/diner" component={Diner} />
                        <Route path="/admin/group" component={Group} />
                        <Route path="/admin/lunch" component={Lunch} />
                        <Route path="/admin/payment" component={Payment} />
                        <Route path="/admin/billing" component={Billing} />
                        <Route path="/admin/alergen" component={Alergen} />
                        <Route path="/admin/emails" component={Emails} />
                        <Route path="/admin/settings" component={Settings} />
                        <Route path="/admin/notice" component={Notice} />
                        <Route path="/admin/order" component={Order} />
                        <Redirect from="/admin" to="/admin/account" />
                    </Switch>
                </div>
            }
            </>
        );
}
};

export default connect(
    state => ({
        profile: state.profile,
        path: state.router.location.pathname
    }),
    {
        push
    }
)(AdminContent);
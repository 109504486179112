import _ from 'lodash';
import * as React from 'react';
import * as toastr from 'toastr';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import Period from '../../../components/Period';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import { toModalDate, toLunchShortDate, toDate, parseDate } from '../../../utils/date';
import { initPeriod } from '../../../utils/period';

import './lunch.scss';


class Lunch extends React.Component {
    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            menus: {},
            modal: {
                open: false,
                date: '',
                lang: 0,
                soup: '',
                soupAlg: '',
                menu1: '',
                menu1Alg: '',
                menu2: '',
                menu2Alg: '',
                menu3: '',
                menu3Alg: '',
                snack: '',
                snackAlg: ''
            },
            period: initPeriod(),
            selected: null,
            lang: '',
            importModal: {
                open: false,
                file: null,
                error: null
            }
        };
        this.api = new ApiService();
        this.mounted = false;
        this.langs = ['en', 'cs'];
    }

    componentWillMount() {
        this.loadMenus();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadMenus() {
        this.api.getMenu(this.state.period)
            .then(response => {
                // console.log(response.data);
                let menus = _.mapKeys(response.data.map(o => this.decodeMenu(o)), o => this.getKey(o.date, o.lang));
                // console.log('Lunch.loadMenus', menus);
                if (this.mounted) {
                    this.setState({ menus });
                }
            })
            .catch(error => {
                console.log('Lunch.loadMenus', error);
            });
    }

    decodeMenu(item) {
        item.date = parseDate(item.date);
        // console.log(item);
        return item;
    }

    addMenu() {
        this.setState({
            modal: {
                ...this.state.modal,
                open: true,
                date: toModalDate(new Date()),
                lang: 0,
                soup: '',
                soupAlg: '',
                menu1: '',
                menu1Alg: '',
                menu2: '',
                menu2Alg: '',
                menu3: '',
                menu3Alg: '',
                snack: '',
                snackAlg: ''
            }
        });
    }

    updateMenu(key) {
        key = key || this.state.selected;
        if (!key) {
            return;
        }

        // console.log('Lunch.updateMenu', { menus: this.state.menus, key });
        let { date, lang, soup, soupAlg, menu1, menu1Alg, menu2, menu2Alg, menu3, menu3Alg, snack, snackAlg } = this.state.menus[key];
        date = toModalDate(date);
        this.setState({
            modal: {
                ...this.state.modal,
                open: true,
                date: date || toModalDate(new Date()),
                lang: lang || 0,
                soup: soup || '',
                soupAlg: soupAlg || '',
                menu1: menu1 || '',
                menu1Alg: menu1Alg || '',
                menu2: menu2 || '',
                menu2Alg: menu2Alg || '',
                menu3: menu3 || '',
                menu3Alg: menu3Alg || '',
                snack: snack || '',
                snackAlg: snackAlg || ''
            }
        });
    }

    remove(key) {
        key = key || this.state.selected;
        if (!key) {
            return;
        }

        if (window.confirm(this.context.translate('Lunch.ConfirmRemove'))) {
            let { date, lang } = this.state.menus[key];
            date = toModalDate(date);

            let _self = this;
            this.api.removeMenu(date, lang)
                .then(response => {
                    if (_self.mounted) {
                        let { menus } = _self.state;
                        delete menus[key];
                        _self.setState({ menus: { ...menus }, selected: '' }, ()=>{
                            _self.loadMenus();
                        });
                        toastr.success(this.context.translate('Lunch.RemoveSuccess'));
                    }
                })
                .catch(error => {
                    console.log('Lunch.remove error remove menu', error);
                    if (_self.mounted) {
                        toastr.error(error);
                    }
                });
        }
    }

    closeModal() {
        this.setState({ modal: { ...this.state.modal, open: false } });
    }

    submitModal() {
        if (this.validateModal()) {
            let { date, lang, soup, soupAlg, menu1, menu1Alg, menu2, menu2Alg, menu3, menu3Alg, snack, snackAlg } = this.state.modal;
            let _self = this;
            this.api.setMenu(date, lang, soup, soupAlg, menu1, menu1Alg, menu2, menu2Alg, menu3, menu3Alg, snack, snackAlg)
                .then(response => {
                    _self.loadMenus();
                    _self.closeModal();
                    toastr.success(this.context.translate('Lunch.SaveSuccess'));
                })
                .catch(error => {
                    console.log('Lunch.submitModal error update menu', error);
                    toastr.error(error);
                });
        }
    }

    validateModal() {
        return true;
    }

    getKey(date, lang) {
        let d = new Date(date);
        return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate()}_${this.langs[lang]}`;
    }

    onPeriodChange(period) {
        this.setState({ period }, () => this.loadMenus());
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? null : key });
    }

    importMenu(file) {
        if (!file) {
            return;
        }

        let data = new FormData();
        data.append('files', file);

        let _self = this;
        this.api.importMenu(data)
            .then(response => {
                console.log('Lunch.importMenu', response.data);
                if (response.data.success) {
                    toastr.success(this.context.translate('Lunch.ImportSuccess', { count: response.data.importedCount + response.data.updatedCount }));
                } else {
                    toastr.error(this.context.translate('Lunch.ImportError', { count: response.data.importedCount + response.data.updatedCount, errCount: response.data.errorCount, errors: response.data.errors.join('</br>') }));
                }
                _self.loadMenus();
                _self.fileInput.value = "";
            })
            .catch(error => {
                console.log('Lunch.importMenu error import menu', error);
                toastr.error(error);
            });
    }

    render() {
        // console.log('Lunch.render');
        const { importModal, menus } = this.state;
        // console.log('Lunch.render', { file: importModal.file });

        let items = arraySort(_.map(menus), ['date', 'lang'])
        if (this.state.lang !== '') {
            items = items.filter(o => o.lang === this.state.lang);
        }
        let isSelected = !!this.state.selected;

        // let fileLabel = 'Vyber soubor';
        // if (importModal.file && importModal.file.name) {
        //     fileLabel = importModal.file.name;
        // }

        return (
            <div className="page-container">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Lunch.Period')}</label>
                            <Period
                                period={this.state.period}
                                onChange={p => this.onPeriodChange(p)}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Lunch.Lang')}</label>
                            <select
                                className="form-control"
                                name="langFilter"
                                value={this.state.lang}
                                onChange={e => this.setState({ lang: e.target.value })}
                            >
                                <option value=''>{this.context.translate('Codelist.All')}</option>
                                <option value={0}>en</option>
                                <option value={1}>cs</option>
                            </select>
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.addMenu()}
                        >{this.context.translate('Btn.Add')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.updateMenu()}
                        >{this.context.translate('Btn.Update')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.remove()}
                        >{this.context.translate('Btn.Delete')}</div>
                        <div className="delimiter" />
                        <div
                            className="btn btn-imsp import-btn"
                        // onClick={() => this.setState({ importModal: { ...this.state.importModal, open: true } })}
                        >
                            {this.context.translate('Btn.Import')}
                            <input
                                type={'file'}
                                onChange={e => this.importMenu(e.target.files[0])}
                                ref={ref => this.fileInput = ref}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                        </div>
                        <div className="row-cont-right">
                            <a
                                className="link-button"
                                href="/doc/menu.xlsx"
                                download
                                target="_blank"
                            >{this.context.translate('Lunch.DownloadImport')}</a>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="menu-table">
                        <colgroup>
                            <col className="col-date"></col>
                            <col className="col-lang"></col>
                            <col className="col-menu"></col>
                            <col className="col-menu"></col>
                            <col className="col-menu"></col>
                            <col className="col-menu"></col>
                            <col className="col-menu"></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="col">{this.context.translate('Lunch.ColDate')}</th>
                                <th className="col">{this.context.translate('Lunch.ColLang')}</th>
                                <th className="col">{this.context.translate('MealType.0')}</th>
                                <th className="col">{this.context.translate('MealType.1')}</th>
                                <th className="col">{this.context.translate('MealType.2')}</th>
                                <th className="col">{this.context.translate('MealType.3')}</th>
                                <th className="col">{this.context.translate('MealType.4')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => {
                                let key = this.getKey(o.date, o.lang);
                                return (
                                    <tr
                                        className={classnames({ selected: this.state.selected === key })}
                                        key={key}
                                        onClick={() => this.select(key)}
                                    >
                                        <td>
                                            <div className="date-container">
                                                <div className="date-day">{this.context.translate(`Days.Short.${toDate(o.date).getDay()}`)}</div>
                                                <div className="date-date">{toLunchShortDate(o.date)}</div>
                                            </div>
                                        </td>
                                        <td className="cell-lang">{this.langs[o.lang]}</td>
                                        <td>{o.soup}{o.soupAlg ? ` [${o.soupAlg}]` : ''}</td>
                                        <td>{o.menu1}{o.menu1Alg ? ` [${o.menu1Alg}]` : ''}</td>
                                        <td>{o.menu2}{o.menu2Alg ? ` [${o.menu2Alg}]` : ''}</td>
                                        <td>{o.menu3}{o.menu3Alg ? ` [${o.menu3Alg}]` : ''}</td>
                                        <td>{o.snack}{o.snackAlg ? ` [${o.snackAlg}]` : ''}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <Modal
                    modalClass="menu-modal"
                    onClose={() => this.closeModal()}
                    onSubmit={() => this.submitModal()}
                    title="Menu"
                    show={this.state.modal.open}
                    closeOnClick={true}
                >
                    <div>
                        <fieldset>
                            <div className="form-group">
                                <label htmlFor="date">Datum (yyyy-MM-dd)</label>
                                <input
                                    className="form-control"
                                    id="date"
                                    name="date"
                                    type="text"
                                    autoFocus
                                    value={this.state.modal.date}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, date: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lang">Jazyk</label>
                                <select
                                    className="form-control"
                                    name="lang"
                                    value={this.state.modal.lang}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, lang: e.target.value } })}
                                >
                                    <option value={0}>en</option>
                                    <option value={1}>cs</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="soup">Polévka</label>
                                <input
                                    className="form-control"
                                    name="soup"
                                    type="text"
                                    value={this.state.modal.soup}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, soup: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="soupAlg">Polévka - alergeny</label>
                                <input
                                    className="form-control"
                                    name="soupAlg"
                                    value={this.state.modal.soupAlg}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, soupAlg: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="menu1">{this.context.translate('MealType.1')}</label>
                                <input
                                    className="form-control"
                                    name="menu1"
                                    type="text"
                                    value={this.state.modal.menu1}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, menu1: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="menu1Alg">{this.context.translate('MealType.1')} - alergeny</label>
                                <input
                                    className="form-control"
                                    name="menu1Alg"
                                    value={this.state.modal.menu1Alg}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, menu1Alg: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="menu1">{this.context.translate('MealType.2')}</label>
                                <input
                                    className="form-control"
                                    name="menu2"
                                    type="text"
                                    value={this.state.modal.menu2}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, menu2: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="menu2Alg">{this.context.translate('MealType.2')} - alergeny</label>
                                <input
                                    className="form-control"
                                    name="menu2Alg"
                                    value={this.state.modal.menu2Alg}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, menu2Alg: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="menu3">{this.context.translate('MealType.3')}</label>
                                <input
                                    className="form-control"
                                    name="menu3"
                                    type="text"
                                    value={this.state.modal.menu3}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, menu3: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="menu3Alg">{this.context.translate('MealType.3')} - alergeny</label>
                                <input
                                    className="form-control"
                                    name="menu3Alg"
                                    value={this.state.modal.menu3Alg}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, menu3Alg: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="snack">Snack</label>
                                <input
                                    className="form-control"
                                    name="snack"
                                    type="text"
                                    value={this.state.modal.snack}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, snack: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="snackAlg">Snack - alergeny</label>
                                <input
                                    className="form-control"
                                    name="snackAlg"
                                    value={this.state.modal.snackAlg}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, snackAlg: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.submitModal()}
                                >Uložit</button>
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeModal()}
                                >Zrušit</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>
                <Modal
                    modalClass="import-menu-modal"
                    onClose={() => this.setState({ importModal: { ...importModal, open: false } })}
                    onSubmit={() => this.setState({ importModal: { ...importModal, open: false } })}
                    title="Import menu"
                    show={importModal.open}
                    closeOnClick={true}
                >
                    <div className="btn btn-imsp import-btn">
                        Import
                        <input
                            type={'file'}
                            onChange={e => console.log('file', e.target.files[0])}
                        />
                    </div>
                    {/* <div className={'form-group'}>
                        <div className="custom-file">
                            <input
                                type={'file'}
                                className={'custom-file-input'}
                                id={'customFile'}
                                onChange={e => this.setState({ importModal: { ...importModal, file: e.target.files[0] } })}
                            />
                            <label htmlFor={'customFile'}><div className="btn btn-primary">Pokus</div></label>
                            
                            <label className={'custom-file-label'} htmlFor={'customFile'}>
                                {fileLabel}
                            </label>
                        </div>
                        {importModal.error &&
                            <div className="invalid-feedback d-block">{importModal.error}</div>}

                    </div> */}
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Lunch);
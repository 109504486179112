export function initPeriod() {
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    return date;
}

export function prevPeriod(period) {
    return new Date(period.getFullYear(), period.getMonth() - 1, 1);
}

export function nextPeriod(value) {
    return new Date(value.getFullYear(), value.getMonth() + 1, 1);
}

export function toPeriod(date) {
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    return date;
}
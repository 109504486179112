import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { prevDay, nextDay, toLunchDate } from '../../utils/date';

import './dateSelector.scss';


class DateSelector extends React.Component {
    static propTypes = {
        date: PropTypes.any,
        onChange: PropTypes.func,
        className: PropTypes.string
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    onPrevPeriod() {
        if (this.props.onChange) {
            this.props.onChange(prevDay(this.props.date));
        }
    }

    onNextPeriod() {
        if (this.props.onChange) {
            this.props.onChange(nextDay(this.props.date));
        }
    }

    render() {
        let text = toLunchDate(this.props.date);
        return (
            <div className={classnames("date-selector", this.props.className)}>
                <div
                    className="btn"
                    onClick={() => this.onPrevPeriod()}
                ><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                <div
                    className="label"
                >{text}</div>
                <div
                    className="btn"
                    onClick={() => this.onNextPeriod()}
                ><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
            </div>
        );
    }
};

export default connect(
    state => ({
    }),
    {}
)(DateSelector);
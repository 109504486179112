import * as React from 'react';
import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { loadProfile } from '../../../redux/actions/profileAction';
import { ApiService } from '../../../services/ApiService';

import './profile.scss';


class Profile extends React.Component {
    static propTypes = {
        profile: PropTypes.object.isRequired,
        loadProfile: PropTypes.func.isRequired
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            edit: {
                state: '',
                name: '',
                address: '',
                accountNumber: '',
                ico: ''
            }
        };
        this.api = new ApiService();
    }

    componentWillMount() {
    }

    saveProfile() {
        if (this.validateProfile()) {
            this.api.updateProfile(this.state.edit.name)
                .then(response => {
                    this.props.loadProfile();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Profile.ProfileSuccess'));
                })
                .catch(error => {
                    console.log('Profile.saveProfile error update profile', error);
                    toastr.error(error);
                });
        }
    }

    saveInvoice() {
        if (this.validateInvoice()) {
            let { name, address, accountNumber, ico } = this.state.edit;
            this.api.updateBilling(name, address, accountNumber, ico)
                .then(response => {
                    this.props.loadProfile();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Profile.BillingSuccess'));
                })
                .catch(error => {
                    console.log('Profile.saveInvoice error update billing informations', error);
                    toastr.error(error);
                });
        }
    }

    validateInvoice() {
        return true;
    }

    validateProfile() {
        return true;
    }

    render() {
        let { edit } = this.state;
        let { name, email, accountNumber, ico, address, billingName } = this.props.profile.data;

        let profileEdit = edit.state === 'profile';
        let invoiceEdit = edit.state === 'invoice';

        return (
            <div className="profile-page">
                <div className="profile-page-content">
                    <div className="section">
                        <div className="section-title">{this.context.translate('Profile.Profile')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('Profile.Name')}</div>
                                {!profileEdit &&
                                    <div className="value">{name}</div>
                                }
                                {profileEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        autoFocus
                                        value={edit.name || ''}
                                        onChange={e => this.setState({ edit: { ...edit, name: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Profile.Email')}</div>
                                <div className="value">{email}</div>
                            </div>
                            <div className="buttons">
                                {!profileEdit &&
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: 'profile', name } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                                {profileEdit &&
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveProfile()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-title">{this.context.translate('Profile.Invoice')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('Profile.Name')}</div>
                                {!invoiceEdit &&
                                    <div className="value">{billingName || name}</div>
                                }
                                {invoiceEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        autoFocus
                                        value={edit.name || ''}
                                        onChange={e => this.setState({ edit: { ...edit, name: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Profile.Address')}</div>
                                {!invoiceEdit &&
                                    <div className="value">{address}</div>
                                }
                                {invoiceEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.address || ''}
                                        onChange={e => this.setState({ edit: { ...edit, address: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Profile.Account')}</div>
                                {!invoiceEdit &&
                                    <div className="value">{accountNumber}</div>
                                }
                                {invoiceEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.accountNumber || ''}
                                        onChange={e => this.setState({ edit: { ...edit, accountNumber: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Profile.Ico')}</div>
                                {!invoiceEdit &&
                                    <div className="value">{ico}</div>
                                }
                                {invoiceEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.ico || ''}
                                        onChange={e => this.setState({ edit: { ...edit, ico: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="buttons">
                                {!invoiceEdit &&
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: 'invoice', name: billingName, address, accountNumber, ico } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                                {invoiceEdit &&
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveInvoice()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        profile: state.profile,
    }), {
        loadProfile
    }
)(Profile);
import _ from 'lodash';
import * as React from 'react';
import * as toastr from 'toastr';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Select from 'react-select';

import Modal from '../../../components/Modal';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';

import './diner.scss';
import schoolYear from '../../../utils/schoolYear';


class Diner extends React.Component {
    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props, context) {
        super(props);

        this.initAssignModal = {
            open: false,
            studentId: ''
        };
        this.state = {
            diners: {},
            modal: {
                open: false,
                id: '',
                name: '',
                group: '',
                user: '',
                isUser: false,
                free: false,
                menu3Enabled: false
            },
            accounts: {},
            groups: {},
            selected: [],
            groupFilter: '',
            allInclusiveFilter: '',
            isUserFilter: '',
            nameFilter: '',
            moveModal: {
                open: false,
                group: ''
            },
            assignModal: this.initAssignModal,
            students: [],
            schyFilter: { value: schoolYear().year(), label: schoolYear().yearText() }
        };
        this.api = new ApiService();
        this.mounted = false;
        this.yesno = { true: 'Ano', false: 'Ne' }
        this.yesNoNullFilterOptions = [
            { value: '', label: context.translate('Codelist.All') },
            { value: 'false', label: context.translate('Codelist.No') },
            { value: 'true', label: context.translate('Codelist.Yes') }
        ]
    }

    componentWillMount() {
        this.loadDiners();
        this.loadAccounts();
        this.loadGroups();
        this.loadStudents();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                let groups = _.mapKeys(response.data, 'id');
                if (this.mounted) {
                    this.setState({ groups });
                }
            })
            .catch(error => {
                console.log('Diners.loadGroups', error);
            });
    }

    loadAccounts() {
        this.api.getAccounts()
            .then(response => {
                let accounts = _.mapKeys(response.data, 'id');
                if (this.mounted) {
                    this.setState({ accounts });
                }
            })
            .catch(error => {
                console.log('Diners.loadAccounts', error);
            });
    }

    loadDiners() {
        let { schyFilter } = this.state;

        let params = {};
        if (schyFilter && schyFilter.value)
            params.year = schyFilter.value;

        this.api.getDiners(params)
            .then(response => {
                if (this.mounted) {
                    let diners = _.mapKeys(response.data, 'id');
                    let { selected, groupFilter } = this.state;
                    selected = this.updateSelected(selected, diners, groupFilter);
                    this.setState({ diners, selected });
                }
            })
            .catch(error => {
                console.log('Diner.loadDiners', error);
                toastr.error(error);
            });
    }

    loadStudents() {
        let { translate } = this.context;

        this.api.getStudents(true)
            .then(response => {
                if (this.mounted) {
                    let students = response.data || [];
                    this.setState({ students });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(translate('Err.System'));
                }
            });
    }

    addDiner() {
        this.setState({
            modal: {
                ...this.state.modal,
                open: true,
                id: '',
                name: '',
                group: '',
                user: '',
                isUser: false,
                menu3Enabled: false,
                hidden: false
            }
        });
    }

    oneSelected() {
        if (this.state.selected.length !== 1) {
            return null;
        }
        return this.state.selected[0];
    }

    updateDiner(id) {
        id = id || this.oneSelected();
        if (!id) {
            return;
        }

        let { name, groupId, userId, isUser, menu3Enabled, hidden, from, to } = this.state.diners[id];
        this.setState({
            modal: {
                ...this.state.modal,
                open: true,
                id,
                name: name || '',
                group: groupId || '',
                user: userId || '',
                isUser: isUser || false,
                menu3Enabled: menu3Enabled || false,
                hidden: hidden || false,
                from: from || '',
                to: to || ''
            }
        });
    }

    closeModal() {
        this.setState({ modal: { ...this.state.modal, open: false } });
    }

    closeMoveModal() {
        this.setState({ moveModal: { ...this.state.moveModal, open: false } });
    }

    submitModal() {
        let { modal } = this.state;
        let { id, name, group, user, isUser, free, menu3Enabled, hidden, from, to } = modal || {};

        if (this.validateModal()) {
            let data = {
                name,
                groupId: group,
                userId: user,
                isUser, free, menu3Enabled, hidden, from, to
            };
            if (id) {
                this.api.updateDiner(id, data)
                    .then(response => {
                        this.loadDiners();
                        this.closeModal();
                        toastr.success('Strávník byl úspěšně upraven.');
                    })
                    .catch(error => {
                        console.log('Diner.submitModal error update diner', error);
                        toastr.error(error);
                    });
            } else {
                this.api.addDiner(data)
                    .then(response => {
                        this.loadDiners();
                        this.closeModal();
                        toastr.success('Strávník byl úspěšně vytvořen.');
                    })
                    .catch(error => {
                        console.log('Diner.submitModal error add diner', error);
                        toastr.error(error);
                    });
            }
        }
    }

    submitMove() {
        if (this.validateMove()) {
            this.api.moveDiners(this.state.selected, this.state.moveModal.group)
                .then(response => {
                    if (this.mounted) {
                        toastr.success(this.context.translate('Diner.MoveSuccess'));
                        this.loadDiners();
                    }
                    this.closeMoveModal();
                })
                .catch(error => {
                    console.log('Diner.submitMove error move diners', error);
                    if (this.mounted) {
                        toastr.error(error);
                    }
                });
        }
    }

    validateMove() {
        return true;
    }

    validateModal() {
        return true;
    }

    select(e, key, items) {
        e.stopPropagation();

        let { selected } = this.state;
        let ctrl = e.metaKey || e.ctrlKey;
        let shift = e.shiftKey;

        if (ctrl) {
            if (selected.includes(key)) {
                this.setState({ selected: selected.filter(o => o !== key) });
            } else {
                this.setState({ selected: [...selected, key] });
            }
        } else if (shift) {
            if (selected.length) {
                let lastSelected = selected[selected.length - 1];
                let idx = [_.findIndex(items, { id: lastSelected }), _.findIndex(items, { id: key })];
                idx.sort((a, b) => a - b);
                for (let i = idx[0]; i <= idx[1]; i++) {
                    if (!selected.includes(items[i].id)) {
                        selected.push(items[i].id);
                    }
                }
                this.setState({ selected: [...selected] });
            } else {
                this.setState({ selected: [key] });
            }
        } else {
            this.setState({ selected: key === this.oneSelected() ? [] : [key] });
        }
    }

    groupFilterChanged(value) {
        let { selected, diners, allInclusiveFilter, isUserFilter } = this.state;
        selected = this.updateSelected(selected, diners, value, allInclusiveFilter, isUserFilter);
        this.setState({ groupFilter: value, selected });
    }

    allInclusiveFilterChanged(value) {
        let { selected, diners, groupFilter, isUserFilter } = this.state;
        selected = this.updateSelected(selected, diners, groupFilter, value, isUserFilter);
        this.setState({ allInclusiveFilter: value, selected });
    }

    isUserFilterChanged(value) {
        let { selected, diners, groupFilter, allInclusiveFilter } = this.state;
        selected = this.updateSelected(selected, diners, groupFilter, allInclusiveFilter, value);
        this.setState({ isUserFilter: value, selected });
    }

    updateSelected(selected, diners, groupFilter, allInclusiveFilter, isUserFilter) {
        if (groupFilter) {
            selected = selected.filter(o => diners[o].groupId === groupFilter);
        }
        if (allInclusiveFilter) {
            selected = selected.filter(o => `${diners[o].free}` === allInclusiveFilter);
        }
        if (isUserFilter) {
            selected = selected.filter(o => `${diners[o].isUser}` === isUserFilter);
        }
        return selected;
    }

    move() {
        if (!this.state.selected.length) {
            return;
        }

        let { groupFilter } = this.state;
        this.setState({
            moveModal: {
                open: true,
                group: groupFilter || ''
            }
        });
    }

    delete() {
        let { selected } = this.state;

        if (!selected.length) {
            return;
        }

        if (window.confirm(this.context.translate('Diner.ConfirmDelete'))) {
            let _self = this;
            this.api.deleteDiner(selected, false)
                .then(response => {
                    if (_self.mounted) {
                        if (response.data) {
                            if (response.data.success) {
                                _self.afterDelete();
                            } else {
                                if (window.confirm(_self.context.translate('Diner.DeleteDependencies'))) {
                                    _self.api.deleteDiner(selected, true)
                                        .then(response => {
                                            if (_self.mounted) {
                                                if (response.data) {
                                                    if (response.data.success) {
                                                        _self.afterDelete();
                                                    } else {
                                                        console.log('Diner.delete: Unexpected response data result.');
                                                    }
                                                } else {
                                                    console.log('Diner.delete: Missing response data.');
                                                }
                                            }
                                        })
                                        .catch(error => {
                                            console.log('Diner.delete error remove diner', error);
                                            if (_self.mounted) {
                                                toastr.error(error);
                                            }
                                        });
                                }
                            }
                        } else {
                            console.log('Diner.delete: Missing response data.');
                        }
                    }
                })
                .catch(error => {
                    console.log('Diner.delete error remove diner', error);
                    if (_self.mounted) {
                        toastr.error(error);
                    }
                });
        }
    }

    afterDelete() {
        let { diners, selected } = this.state;

        diners = { ...diners };
        delete diners[selected];
        this.setState({ diners, selected: '' }, () => {
            this.loadDiners();
        });
        toastr.success(this.context.translate('Diner.DeleteSuccess'));
    }

    getAssignStudentDisabled(selected, diners) {
        if (!selected || !selected.length || selected.length > 1 ||
            !diners || !(selected[0] in diners) || !diners[selected[0]] || diners[selected[0]].studentId) {
            return true;
        }
        return false;
    }

    assignStudent() {
        let { students } = this.state;
        let studentId = students && students.length ? students[0].id : this.initAssignModal.studentId;
        this.setState({ assignModal: { ...this.initAssignModal, open: true, studentId } });
    }

    closeAssignModal() {
        this.setState({ assignModal: { ...this.initAssignModal } });
    }

    submitAssignModal() {
        let { selected, assignModal } = this.state;
        let { translate } = this.context;

        if (!selected || !assignModal.studentId) {
            return;
        }

        if (window.confirm(translate('Diner.AssignConfirm'))) {
            this.api.setDinerStudent(selected, assignModal.studentId)
                .then(response => {
                    if (this.mounted) {
                        toastr.success(translate('Diner.AssignSuccess'));
                        this.loadDiners();
                        this.loadStudents();
                    }
                    this.closeAssignModal();
                })
                .catch(error => {
                    if (this.mounted) {
                        toastr.error(translate('Err.System'));
                    }
                });
        }
    }

    getSchyFilterOptions() {
        let curYear = schoolYear().year();
        let result = [];
        for (let i = 2020; i <= curYear + 1; i++) {
            result.push({ value: i, label: schoolYear(i).yearText() })
        }
        return result;
    }

    render() {
        let { selected, groupFilter, allInclusiveFilter, isUserFilter, nameFilter, diners,
            assignModal, students, schyFilter, modal } = this.state;
        let { translate } = this.context;

        let items = arraySort(_.map(diners), 'name');
        if (groupFilter) {
            items = items.filter(o => o.groupId === groupFilter);
        }
        if (allInclusiveFilter) {
            items = items.filter(o => `${o.free}` === allInclusiveFilter);
        }
        if (isUserFilter) {
            items = items.filter(o => `${o.isUser}` === isUserFilter);
        }
        items.forEach(o => o.userName = o.userId ? (this.state.accounts[o.userId] || {}).name : '');
        if (nameFilter) {
            items = items.filter(o => (o.name || '').toLowerCase().includes(nameFilter.toLowerCase().trim())
                || (o.userName || '').toLowerCase().includes(nameFilter.toLowerCase().trim()));
        }
        let accounts = [];
        let groups = [{ id: '', name: this.context.translate('Codelist.All') }, ...arraySort(_.map(this.state.groups), 'name')];
        if (this.state.modal.open) {
            accounts = [{ id: '', name: '' }, ...arraySort(_.map(this.state.accounts), 'name')];
        }
        let isSelected = !!selected.length;
        let isOneSelected = !!this.oneSelected();
        let assignStudentDisabled = this.getAssignStudentDisabled(selected, diners);

        let schyFilterOptions = this.getSchyFilterOptions();

        return (
            <div className="page-container diner-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="name"
                                value={nameFilter}
                                onChange={e => this.setState({ nameFilter: e.target.value })}
                                autoFocus
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Group')}</label>
                            <select
                                className="form-control"
                                name="group"
                                value={this.state.groupFilter}
                                onChange={e => this.groupFilterChanged(e.target.value)}
                            >
                                {groups.map(o => (
                                    <option key={o.id || 0} value={o.id}>{o.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.AllInclusive')}</label>
                            <select
                                className="form-control"
                                name="allInclusive"
                                value={this.state.allInclusiveFilter}
                                onChange={e => this.allInclusiveFilterChanged(e.target.value)}
                            >
                                {this.yesNoNullFilterOptions.map(o => (
                                    <option key={o.value} value={o.value}>{o.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.IsUser')}</label>
                            <select
                                className="form-control"
                                name="isUser"
                                value={this.state.isUserFilter}
                                onChange={e => this.isUserFilterChanged(e.target.value)}
                            >
                                {this.yesNoNullFilterOptions.map(o => (
                                    <option key={o.value} value={o.value}>{o.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-group">
                            <label>{translate('General.SchoolYear')}</label>
                            <Select
                                value={schyFilter}
                                options={schyFilterOptions}
                                onChange={schyFilter => this.setState({ schyFilter }, () => {
                                    this.loadDiners();
                                })}
                                menuPortalTarget={document.querySelector('body')}
                                isClearable
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.addDiner()}
                        >{this.context.translate('Btn.Add')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isOneSelected })}
                            onClick={() => this.updateDiner()}
                        >{this.context.translate('Btn.Update')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.move()}
                        >{this.context.translate('Diner.Move')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: assignStudentDisabled })}
                            onClick={() => { if (!assignStudentDisabled) this.assignStudent(); }}
                        >{this.context.translate('Diner.AssignStudent')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isOneSelected })}
                            onClick={() => this.delete()}
                        >{this.context.translate('Btn.Delete')}</div>
                        <div className="delimiter" />
                        <div
                            className="btn btn-imsp import-btn"
                        >
                            {this.context.translate('Btn.Import')}
                            <input
                                type={'file'}
                                onChange={e => this.importMenu(e.target.files[0])}
                                ref={ref => this.fileInput = ref}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-name" />
                            <col className="col-group" />
                            <col className="col-account" />
                            <col className="col-isaccount" />
                            <col className="col-free" />
                            <col className="col-glutenfree" />
                            <col className="col-paymentid" />
                            <col className="col-balance" />
                            <col className="col-unposted" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="col">{this.context.translate('Diner.Name')}</th>
                                <th className="col">{this.context.translate('Diner.Group')}</th>
                                <th className="col">{this.context.translate('Diner.Account')}</th>
                                <th className="col ta-c">{this.context.translate('Diner.UserDiner')}</th>
                                <th className="col ta-c">{this.context.translate('Diner.NotPay')}</th>
                                <th className="col ta-c">{this.context.translate('Diner.GlutenFree')}</th>
                                <th className="col ta-c">{this.context.translate('Diner.PaymentId')}</th>
                                <th className="col ta-c">{this.context.translate('Diner.Balance')}</th>
                                <th className="col ta-c">{this.context.translate('Diner.Unposted')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => (
                                <tr
                                    key={o.id}
                                    className={classnames({ selected: selected.includes(o.id) })}
                                    onClick={e => this.select(e, o.id, items)}
                                >
                                    <td>{o.name}</td>
                                    <td>{o.groupId ? (this.state.groups[o.groupId] || {}).name : ''}</td>
                                    <td>{o.userName}</td>
                                    <td className="ta-c">{this.yesno[o.isUser || false]}</td>
                                    <td className="ta-c">{this.yesno[o.free || false]}</td>
                                    <td className="ta-c">{this.yesno[o.menu3Enabled || false]}</td>
                                    <td className="ta-c">{o.paymentId}</td>
                                    <td className="ta-r">{o.balance} {this.context.translate('Units.Money')}</td>
                                    <td className="ta-r">{o.unpostedOrders} {this.context.translate('Units.Money')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Modal
                        modalClass="assing-modal"
                        onClose={() => this.closeAssignModal()}
                        onSubmit={() => this.submitAssignModal()}
                        title={translate('Diner.AssignModalTitle')}
                        show={assignModal.open}
                        closeOnClick={true}
                    >
                        <div>
                            <fieldset>
                                <div className="form-group">
                                    <label>{translate('Diner.Student')}</label>
                                    <select
                                        className="form-control"
                                        value={assignModal.studentId}
                                        onChange={e => this.setState({ assignModal: { ...assignModal, studentId: e.target.value } })}
                                    >
                                        {students.map(o => (
                                            <option key={o.id || 0} value={o.id}>{o.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group buttons-r">
                                    <button
                                        className="btn btn-light"
                                        onClick={() => this.closeAssignModal()}
                                    >{translate('Btn.Cancel')}</button>
                                    <button
                                        className={classnames('btn btn-imsp', { disabled: !assignModal.studentId })}
                                        onClick={() => this.submitAssignModal()}
                                    >{translate('Diner.Assign')}</button>
                                </div>
                            </fieldset>
                        </div>
                    </Modal>

                    <Modal
                        modalClass="diner-modal"
                        onClose={() => this.closeModal()}
                        onSubmit={() => this.submitModal()}
                        title={this.context.translate('Diner.ModalTitle')}
                        show={this.state.modal.open}
                        closeOnClick={true}
                    >
                        <div>
                            <fieldset>
                                <input
                                    type="hidden"
                                    id="id"
                                    name="id"
                                />
                                <div className="form-group">
                                    <label htmlFor="name">{this.context.translate('Diner.Name')}</label>
                                    <input
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        type="text"
                                        autoFocus
                                        value={this.state.modal.name}
                                        onChange={e => this.setState({ modal: { ...this.state.modal, name: e.target.value } })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="group">{this.context.translate('Diner.Group')}</label>
                                    <select
                                        className="form-control"
                                        name="group"
                                        value={this.state.modal.group}
                                        onChange={e => this.setState({ modal: { ...this.state.modal, group: e.target.value } })}
                                    >
                                        {groups.map(o => (
                                            <option key={o.id || 0} value={o.id}>{o.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="account">{this.context.translate('Diner.Account')}</label>
                                    <select
                                        className="form-control"
                                        name="account"
                                        value={this.state.modal.user}
                                        onChange={e => this.setState({ modal: { ...this.state.modal, user: e.target.value } })}
                                    >
                                        {accounts.map(o => (
                                            <option key={o.id || 0} value={o.id}>{o.name}</option>
                                        ))}
                                    </select>
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="isUser">{this.context.translate('Diner.UserDiner')}</label>
                                    <select
                                        className="form-control"
                                        name="isUser"
                                        value={this.state.modal.isUser}
                                        onChange={e => {
                                            // console.log(`${this.state.modal.isUser} -> ${e.target.value}`);
                                            this.setState({ modal: { ...this.state.modal, isUser: e.target.value } });
                                        }}
                                    >
                                        {Object.keys(this.yesno).map(o => (
                                            <option key={o} value={o}>{this.yesno[o]}</option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="free">{this.context.translate('Diner.NotPay')}</label>
                                    <select
                                        className="form-control"
                                        name="free"
                                        value={this.state.modal.free}
                                        onChange={e => {
                                            // console.log(`${this.state.modal.free} -> ${e.target.value}`);
                                            this.setState({ modal: { ...this.state.modal, free: e.target.value } });
                                        }}
                                    >
                                        {Object.keys(this.yesno).map(o => (
                                            <option key={o} value={o}>{this.yesno[o]}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="glutenfree">{this.context.translate('Diner.GlutenFree')}</label>
                                    <select
                                        className="form-control"
                                        name="glutenfree"
                                        value={this.state.modal.menu3Enabled}
                                        onChange={e => {
                                            this.setState({ modal: { ...this.state.modal, menu3Enabled: e.target.value } });
                                        }}
                                    >
                                        {Object.keys(this.yesno).map(o => (
                                            <option key={o} value={o}>{this.yesno[o]}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="hidden">{this.context.translate('Diner.Hidden')}</label>
                                    <select
                                        className="form-control"
                                        name="hidden"
                                        value={this.state.modal.hidden}
                                        onChange={e => {
                                            // console.log(`${this.state.modal.free} -> ${e.target.value}`);
                                            this.setState({ modal: { ...this.state.modal, hidden: e.target.value } });
                                        }}
                                    >
                                        {Object.keys(this.yesno).map(o => (
                                            <option key={o} value={o}>{this.yesno[o]}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>{this.context.translate('Diner.From')}</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        autoComplete="off"
                                        value={modal.from}
                                        onChange={e => this.setState({ modal: { ...modal, from: e.target.value } })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{this.context.translate('Diner.To')}</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        autoComplete="off"
                                        value={modal.to}
                                        onChange={e => this.setState({ modal: { ...modal, to: e.target.value } })}
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => this.submitModal()}
                                    >{this.context.translate(this.state.modal.id ? 'Btn.Update' : 'Btn.Add')}</button>
                                    <button
                                        className="btn btn-light"
                                        onClick={() => this.closeModal()}
                                    >{translate('Btn.Cancel')}</button>
                                </div>
                            </fieldset>
                        </div>
                    </Modal>

                    {this.state.moveModal.open &&
                        <Modal
                            modalClass="move-diner-modal"
                            onClose={() => this.closeMoveModal()}
                            onSubmit={() => this.submitMove()}
                            title={this.context.translate('Diner.MoveModalTitle')}
                            show={this.state.moveModal.open}
                            closeOnClick={true}
                        >
                            <div>
                                <fieldset>
                                    <div className="form-group">
                                        <label htmlFor="group">{this.context.translate('Diner.Group')}</label>
                                        <select
                                            className="form-control"
                                            name="group"
                                            value={this.state.moveModal.group}
                                            onChange={e => this.setState({ moveModal: { ...this.state.moveModal, group: e.target.value } })}
                                        >
                                            {groups.map(o => (
                                                <option key={o.id || 0} value={o.id}>{o.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => this.submitMove()}
                                        >{this.context.translate('Diner.MoveSubmit')}</button>
                                        <button
                                            className="btn btn-light"
                                            onClick={() => this.closeMoveModal()}
                                        >{this.context.translate('Btn.Cancel')}</button>
                                    </div>
                                </fieldset>
                            </div>
                        </Modal>
                    }

                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Diner);
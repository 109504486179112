import moment from 'moment';


class SchoolYear {
    #year;

    constructor(year) {
        this.#year = year;
    }

    year() {
        return this.#year;
    }

    yearText() {
        return `${this.#year} / ${(this.#year + 1) % 100}`;
    }
}

function schoolYear(input) {
    if (input === null || input === undefined) {
        return new SchoolYear(fromMoment(moment()));
    }

    if (Number.isInteger(input)) {
        return new SchoolYear(input);
    }

    try {
        return new SchoolYear(fromMoment(moment(input)));
    } catch (error) {
        console.log('Error parse input:', input);
        return new SchoolYear(fromMoment(moment()));
    }
}

function fromMoment(value) {
    return value.isBefore(moment(`${value.year()}-09-01`)) ? value.year() - 1 : value.year();
}

export default schoolYear;

export function getSchyFilterOptions(def) {
    let curYear = schoolYear().year();
    let from = def.from || (curYear + def.relFrom);
    let to = def.to || (curYear + def.relTo);
    let result = [];
    for (let i = from; i <= to; i++) {
        result.push({ value: i, label: schoolYear(i).yearText() })
    }
    return result;
}
import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';

import './alergen.scss';


class Alergen extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            alergens: {},
            selected: '',
            modal: {
                open: false,
                id: '',
                cs: '',
                en: ''
            }
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadAlergens();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadAlergens() {
        this.api.getAlergens()
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    this.setState({ alergens: data });
                }
            })
            .catch(error => {
                console.log('Alergen.loadAlergens error', error);
            })
    }

    add() {
        this.setState({
            modal: {
                ...this.state.modal,
                open: true,
                id: '',
                cs: '',
                en: ''
            }
        });
    }

    update(idx) {
        idx = idx || this.state.selected;
        if (!idx) {
            return;
        }

        let { id, cs, en } = this.state.alergens[idx];
        this.setState({
            modal: {
                ...this.state.modal,
                open: true,
                id: id || idx,
                cs: cs || '',
                en: en || ''
            }
        });
    }

    delete(idx) {
        idx = idx || this.state.selected;
        if (!idx) {
            return;
        }

        this.api.deleteAlergen(idx)
            .then(response => {
                this.loadAlergens();
                toastr.success(this.context.translate('Alergen.DeleteSuccess'));
            })
            .catch(error => {
                console.log('Alergen.delete error delete alergen', error);
                toastr.error(error);
            });
    }

    closeModal() {
        this.setState({ modal: { ...this.state.modal, open: false } });
    }

    submitModal() {
        if (this.validateModal()) {
            let { id, cs, en } = this.state.modal;

            this.api.setAlergen(id, cs, en)
                .then(response => {
                    this.loadAlergens();
                    this.closeModal();
                    toastr.success(this.context.translate('Alergen.SaveSuccess'));
                })
                .catch(error => {
                    console.log('Alergen.submitModal error save alergen', error);
                    toastr.error(error);
                });
        }
    }

    validateModal() {
        return true;
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? null : key });
    }

    render() {
        let { alergens, selected, modal } = this.state;

        let isSelected = !!selected;
        let items = arraySort(_.map(alergens), 'id');

        return (
            <div className="page-container alergen-page">
                <div className="page-menu">
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.add()}
                        >{this.context.translate('Btn.Add')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.update()}
                        >{this.context.translate('Btn.Update')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.delete()}
                        >{this.context.translate('Btn.Delete')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-id" />
                            <col className="col-cs" />
                            <col className="col-en" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{this.context.translate('Alergen.Id')}</th>
                                <th>{this.context.translate('Alergen.Cs')}</th>
                                <th>{this.context.translate('Alergen.En')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => {
                                return (
                                    <tr
                                        key={o.id}
                                        className={classnames({ selected: selected === o.id })}
                                        onClick={() => this.select(o.id)}
                                    >
                                        <td>{o.id}</td>
                                        <td>{o.cs}</td>
                                        <td>{o.en}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <Modal
                        modalClass="imsp-modal"
                        onClose={() => this.closeModal()}
                        onSubmit={() => this.submitModal()}
                        title={this.context.translate('Alergen.ModalTitle')}
                        show={modal.open}
                        closeOnClick={true}
                    >
                        <div>
                            <fieldset>
                                <div className="form-group">
                                    <label htmlFor="id">{this.context.translate('Alergen.Id')}</label>
                                    <input
                                        className="form-control"
                                        name="id"
                                        type="text"
                                        autoFocus
                                        value={modal.id}
                                        onChange={e => this.setState({ modal: { ...modal, id: e.target.value } })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="cs">{this.context.translate('Alergen.Cs')}</label>
                                    <input
                                        className="form-control"
                                        name="cs"
                                        type="text"
                                        value={modal.cs}
                                        onChange={e => this.setState({ modal: { ...modal, cs: e.target.value } })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="en">{this.context.translate('Alergen.En')}</label>
                                    <input
                                        className="form-control"
                                        name="en"
                                        type="text"
                                        value={modal.en}
                                        onChange={e => this.setState({ modal: { ...modal, en: e.target.value } })}
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => this.submitModal()}
                                    >{this.context.translate('Btn.Save')}</button>
                                    <button
                                        className="btn btn-light"
                                        onClick={() => this.closeModal()}
                                    >{this.context.translate('Btn.Cancel')}</button>
                                </div>
                            </fieldset>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Alergen);
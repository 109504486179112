import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Period from '../../../components/Period';
import { arraySort } from '../../../utils/sort';
import { initPeriod, prevPeriod } from '../../../utils/period';
import { toDate, toPeriodDate } from '../../../utils/date';
import { ApiService } from '../../../services/ApiService';


class Billing extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            period: prevPeriod(initPeriod()),
            billings: {},
            diners: {
                data: {},
                selected: ''
            }
        };
        this.api = new ApiService();
        this.mounted = false;
    }

    componentWillMount() {
        this.loadBillings();
        this.loadDiners();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadBillings() {
        this.api.getBillings(this.state.period)
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, o => this.getKey(o.month, o.dinerId));
                    this.setState({ billings: data });
                }
            })
            .catch(error => {
                console.log('Billing.loadBillings error', error);
            })
    }

    loadDiners() {
        let { diners } = this.state;

        this.api.getDiners()
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    this.setState({ diners: { ...diners, data } });
                }
            })
            .catch(error => {
                console.log('Billing.loadDiners error', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            })
    }

    getKey(date, diner) {
        let d = toDate(date);
        return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${diner}`;
    }

    onPeriodChange(period) {
        this.setState({ period }, () => {
            this.loadBillings();
        });
    }

    export() {
        let { period } = this.state;
        this.api.exportBillings(period)
            .then(response => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `billings-${toPeriodDate(period)}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.log('Billing.export error', error);
            })
    }

    selectedDinerChanged(id) {
        let { diners } = this.state;
        this.setState({ diners: { ...diners, selected: id } }, () => {
            // this.loadHistories();
        });
    }

    recalculate() {
        let { period } = this.state;

        let date = toDate(period);
        let periodText = `${this.context.translate('Months.Basic.' + (date.getMonth() + 1))} ${date.getFullYear()}`;

        if (window.confirm(this.context.translate('Billing.ConfirmRecalculate').replace(/{period}/g, periodText))) {
            this.api.recalculateBillings(period)
                .then(response => {
                    if (this.mounted) {
                        toastr.info(this.context.translate('Billing.RecalculateSent'));
                    }
                })
                .catch(error => {
                    console.log('Billing.recalculate error', error);
                    if (this.mounted) {
                        toastr.error(error);
                    }
                })
        }
    }

    render() {
        let { period, billings, diners } = this.state;

        let dinnerArray = arraySort(_.map(diners.data), 'name');
        let dinerItems = [{ id: '', name: this.context.translate('Codelist.All') }, ...dinnerArray];
        let items = arraySort(_.map(billings), { prop: 'user.name', order: 'asc' }, { prop: 'diner.name', order: 'asc' });
        if (diners.selected) {
            items = items.filter(o => o.dinerId === diners.selected)
        }

        let { sumaCreditBefore, sumaMenu, sumaSnack, sumaTotal, sumaCredit, sumaDeposit } = items.reduce((r, o) => {
            r.sumaCreditBefore += o.creditBefore;
            r.sumaMenu += o.menuAmount;
            r.sumaSnack += o.snackAmount;
            r.sumaTotal += o.totalAmount;
            r.sumaCredit += o.credit;
            r.sumaDeposit += o.deposit;
            return r;
        }, { sumaCreditBefore: 0, sumaMenu: 0, sumaSnack: 0, sumaTotal: 0, sumaCredit: 0, sumaDeposit: 0 });
        let balance = sumaCredit - sumaCreditBefore;

        return (
            <div className="page-container">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Period')}</label>
                            <Period
                                period={period}
                                onChange={p => this.onPeriodChange(p)}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Diner')}</label>
                            <select
                                className="form-control diner-selector"
                                name="diner"
                                value={diners.selected}
                                onChange={e => this.selectedDinerChanged(e.target.value)}
                            >
                                {dinerItems.map(o => (
                                    <option key={o.id || 0} value={o.id}>{o.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.export()}
                        >{this.context.translate('Btn.Export')}</div>
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.recalculate()}
                        >{this.context.translate('Billing.Recalculate')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-user" />
                            <col className="col-diner" />
                            <col className="col-creditbefore" />
                            <col className="col-menu" />
                            <col className="col-snack" />
                            <col className="col-deposit" />
                            <col className="col-total" />
                            <col className="col-credit" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{this.context.translate('Billing.User')}</th>
                                <th>{this.context.translate('Billing.Diner')}</th>
                                <th className="ta-r">{this.context.translate('Billing.CreditBefore')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Menu')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Snack')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Deposit')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Total')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Credit')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => {
                                let key = this.getKey(o.month, o.dinerId);
                                return (
                                    <tr
                                        key={key}
                                    >
                                        <td>{(o.user || {}).name}</td>
                                        <td>{(o.diner || {}).name}</td>
                                        <td className="ta-r">{o.creditBefore} {this.context.translate('Units.Money')}</td>
                                        <td className="ta-r">{o.menuAmount} {this.context.translate('Units.Money')}</td>
                                        <td className="ta-r">{o.snackAmount} {this.context.translate('Units.Money')}</td>
                                        <td className="ta-r">{o.deposit} {this.context.translate('Units.Money')}</td>
                                        <td className="ta-r">{o.totalAmount} {this.context.translate('Units.Money')}</td>
                                        <td className="ta-r">{o.credit} {this.context.translate('Units.Money')}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-suma" />
                            <col className="col-balance" />
                            <col className="col-creditbefore" />
                            <col className="col-menu" />
                            <col className="col-snack" />
                            <col className="col-deposit" />
                            <col className="col-total" />
                            <col className="col-credit" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="ta-c">{this.context.translate('Billing.Suma')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Balance')}</th>
                                <th className="ta-r">{this.context.translate('Billing.CreditBefore')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Menu')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Snack')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Deposit')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Total')}</th>
                                <th className="ta-r">{this.context.translate('Billing.Credit')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td className="ta-r">{balance} {this.context.translate('Units.Money')}</td>
                                <td className="ta-r">{sumaCreditBefore} {this.context.translate('Units.Money')}</td>
                                <td className="ta-r">{sumaMenu} {this.context.translate('Units.Money')}</td>
                                <td className="ta-r">{sumaSnack} {this.context.translate('Units.Money')}</td>
                                <td className="ta-r">{sumaDeposit} {this.context.translate('Units.Money')}</td>
                                <td className="ta-r">{sumaTotal} {this.context.translate('Units.Money')}</td>
                                <td className="ta-r">{sumaCredit} {this.context.translate('Units.Money')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Billing);
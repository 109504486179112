import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import { today, toModalDate, parseDate, toStandardDmySpan } from '../../../utils/date';

class Order extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            diners: {
                data: {},
                selected: ''
            },
            from: toModalDate(today()),
            to: toModalDate(today()),
            orders: {},
            selected: []
        };
        this.mounted = false;
        this.api = new ApiService();
        this.loadDelayed = _.debounce(() => this.loadOrders(), 200);
        this.memGetOrderList = memoize(this.getOrderList);
    }

    componentWillMount() {
        this.loadDiners();
        this.loadOrders();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadDiners() {
        let { diners } = this.state;

        this.api.getDiners()
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    this.setState({ diners: { ...diners, data } });
                }
            })
            .catch(error => {
                console.log('Order.loadDiners error', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            })
    }

    loadOrders() {
        let { from, to, diners } = this.state;

        from = parseDate(from);
        to = parseDate(to);
        if (!from || !to) {
            console.log('Invalid from-to parameters.');
            return;
        }

        this.api.getAdminOrders(from, to, diners.selected)
            .then(response => {
                if (this.mounted) {
                    let orders = _.mapKeys(response.data.map(o => ({ ...o, key: this.getOrderKey(o) })), 'key');
                    this.setState({ orders }, () => this.updateSelected());
                }
            })
            .catch(error => {
                console.log('Order.loadOrders error', error);
                if (this.mounted) {
                    toastr.error(error);
                }
            });
    }

    getOrderKey(order) {
        return `${order.dinerId}_${parseDate(order.date)}`;
    }

    selectedDinerChanged(id) {
        let { diners } = this.state;
        this.setState({ diners: { ...diners, selected: id } }, () => this.updateSelected());
    }

    delete(id) {
        let { selected, orders } = this.state;

        if (!selected.length) {
            return;
        }

        if (window.confirm(this.context.translate('Order.ConfirmDelete'))) {
            let keys = _.map(orders)
                .filter(o => selected.includes(o.key))
                .map(o => ({ dinerId: o.dinerId, date: o.date }));
            this.api.deleteOrders(keys)
                .then(response => {
                    if (this.mounted) {
                        toastr.success(this.context.translate('Order.DeletedSuccessfuly'));
                        this.loadOrders();
                    }
                })
                .catch(error => {
                    console.log('Order.delete error', error);
                    if (this.mounted) {
                        toastr.error(error);
                    }
                });
        }
    }

    onFromChange(e) {
        let from = e.target.value;
        this.setState({ from }, () => this.loadDelayed());
    }

    onToChange(e) {
        let to = e.target.value;
        this.setState({ to }, () => this.loadDelayed());
    }

    getOrderList(orders, selectedDiner) {
        let result = _.map(orders).filter(o => !!o.menu || o.snack);
        if (selectedDiner) {
            result = result.filter(o => o.dinerId === selectedDiner);
        }
        result = arraySort(result, { prop: 'date', order: 'asc' }, { prop: 'diner.name', order: 'asc' });
        console.log('Order.getOrderList', { selectedDiner });
        return result;
    }

    select(e, key, items) {
        e.stopPropagation();

        let { selected } = this.state;
        let ctrl = e.metaKey || e.ctrlKey;
        let shift = e.shiftKey;

        if (ctrl) {
            if (selected.includes(key)) {
                this.setState({ selected: selected.filter(o => o !== key) });
            } else {
                this.setState({ selected: [...selected, key] });
            }
        } else if (shift) {
            if (selected.length) {
                let lastSelected = selected[selected.length - 1];
                let idx = [_.findIndex(items, { key: lastSelected }), _.findIndex(items, { key: key })];
                idx.sort((a, b) => a - b);
                for (let i = idx[0]; i <= idx[1]; i++) {
                    if (!selected.includes(items[i].key)) {
                        selected.push(items[i].key);
                    }
                }
                this.setState({ selected: [...selected] });
            } else {
                this.setState({ selected: [key] });
            }
        } else {
            this.setState({ selected: selected.length === 1 && key === selected[0] ? [] : [key] });
        }
    }

    updateSelected() {
        let { diners, orders, selected } = this.state;
        let orderList = this.memGetOrderList(orders, diners.selected);
        let keys = orderList.map(o => o.key);

        let prev = selected.length;
        selected = selected.filter(o => keys.includes(o));
        if (selected.length !== prev) {
            this.setState({ selected });
        }
    }

    render() {
        let { diners, from, to, selected, orders } = this.state;

        let dinnerArray = arraySort(_.map(diners.data), 'name');
        let dinerItems = [{ id: '', name: this.context.translate('Codelist.All') }, ...dinnerArray];

        let orderList = this.memGetOrderList(orders, diners.selected);
        let deleteDisabled = !selected.length;
        console.log('Order.render', { orders: orderList });

        return (
            <div className="page-container">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.From')}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="from"
                                value={from}
                                onChange={e => this.onFromChange(e)}
                                autoFocus
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.To')}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="to"
                                value={to}
                                onChange={e => this.onToChange(e)}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Diner')}</label>
                            <select
                                className="form-control diner-selector"
                                name="diner"
                                value={diners.selected}
                                onChange={e => this.selectedDinerChanged(e.target.value)}
                            >
                                {dinerItems.map(o => (
                                    <option key={o.id || 0} value={o.id}>{o.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className={classnames("btn btn-imsp", { disabled: deleteDisabled })}
                            onClick={() => this.delete()}
                        >{this.context.translate('Btn.Delete')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-date" />
                            <col className="col-diner" />
                            <col className="col-lunch" />
                            <col className="col-snack" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{this.context.translate('Order.Date')}</th>
                                <th>{this.context.translate('Order.Diner')}</th>
                                <th>{this.context.translate('Order.Lunch')}</th>
                                <th>{this.context.translate('Order.Snack')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderList.map(o => {
                                return (
                                    <tr
                                        key={o.key}
                                        className={classnames({ selected: selected.includes(o.key) })}
                                        onClick={e => this.select(e, o.key, orderList)}
                                    >
                                        <td>{toStandardDmySpan(o.date)}</td>
                                        <td>{(o.diner || {}).name}</td>
                                        <td>{!!o.menu ? (o.menu === 1 ? 'M' : (o.menu === 2 ? 'V' : o.menu)) : ''}</td>
                                        <td>{!!o.snack ? 'S' : ''}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Order);
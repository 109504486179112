import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/build/toastr.min.css';

import 'react-app-polyfill/ie11';
import 'core-js';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc';
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router'

import { unregister } from './registerServiceWorker';
import createStore from './redux/createStore';
import userManager from './utils/userManager';
import App from './containers/App';
import Callback from './components/Callback';

import './index.css';
import './styles/flex.scss';

require("es6-promise").polyfill();
export const history = createBrowserHistory();
const store = createStore(history);
// console.log('store', store);

ReactDOM.render((
    <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path="/callback" component={Callback} />
                    <Route path="/" component={App} />
                </Switch>
            </ConnectedRouter>
        </OidcProvider>
    </Provider>
), document.getElementById('root'));
// registerServiceWorker();
unregister();
